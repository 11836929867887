import React from "react";
import {Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";

export default (props) => (
  <View style={[styles.container, props.style]}>
      <Text style={styles.text} type="menuLink">{props.children}</Text>
  </View>
);

const styles = StyleSheet.create({
    container: {
        paddingBottom: 10,
    },
    text: {
        fontFamily: 'Aino-Bold',
        fontSize: 20,
    },
});
