import {SET_USER_DATA} from "../actions";
import {Mixpanel} from "../../utils/mixpanel";
import mixpanel from "mixpanel-browser";

export default function userDataReducer(state = null, action) {
    switch (action.type) {
        case SET_USER_DATA:
            if (action.payload !== null) {
                window.fbq("consent", "grant");
                mixpanel.init(process.env.MIXPANEL_TOKEN, {"api_host": "https://api-eu.mixpanel.com"}, "");
                Mixpanel.identify(action.payload.id);
            }
            return action.payload;
        default:
            return state;
    }
}
