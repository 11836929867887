import React, {useEffect, useRef, useState} from "react";
import {Text, View} from "react-native-web-ui-components";
import {connect} from "react-redux";
import {ImageBackground, StyleSheet} from "react-native";
import {useDimensions} from "react-native-responsive-ui/src";
import Button from "../components/Button";
import Link from "../components/Link";
import {TOP_MENU_HEIGHT} from "../constants";
import BookSlider from "../components/BookSlider";
import IconButton from "../components/IconButton";
import axios from "axios";
import {useHistory} from "../routing";

const introImage = { uri: require('../assets/images/ylemine_jan_025.jpg') }
const giftBannerImage = { uri: require('../assets/images/gift-card-banner.png') }
const footerImage = { uri: require('../assets/images/alumine_jan.jpg') }

const scrollToRef = ref => ref.current.scrollIntoView({
    behavior: 'smooth',
})

function LandingScreen(props) {
    const instructionsRef = useRef(null)
    const history = useHistory();
    const scrollToInstructions = () => scrollToRef(instructionsRef)
    const date = new Date()
    const isTestPeriodOver = date.getFullYear() > 2020 || date.getMonth() > 8
    const styles = useStylesheet()
    const [sliders, setSliders] = useState(undefined);

    const fetchSliders = async () => {
        const res = await axios.get(`${(process.env.API2_URL)}/sliders`);
        setSliders(res.data);
    }

    useEffect(() => {
        fetchSliders();
    }, [])

    return (
        <View style={styles.container}>
            <ImageBackground source={introImage} style={styles.introImage}>
                <View style={styles.intro}>
                    <Text style={styles.introTitle}>{props.introTitle}</Text>
                    <Text style={styles.introContent}>
                        {props.introContent}
                    </Text>
                    <View style={styles.introLinksContainer}>
                        <Button style={styles.introButton} to="/registreerumine">Proovi 14 päeva tasuta!</Button>
                        <Link textStyle={styles.introLink} textHoverStyle={styles.introLinkHover}
                              onPress={scrollToInstructions}>Kuidas kuulata?</Link>
                    </View>
                    <Text style={styles.introSubText}>
                        {isTestPeriodOver ? '' : '* Testperioodi lõpuni 30.09.2020!'}
                    </Text>
                </View>
            </ImageBackground>

            <View style={styles.promo}>
                <Text style={styles.promoTitle}>{props.promoTitle}</Text>
                <Text style={styles.promoContent}>{props.promoContent}</Text>
                {sliders !== undefined && sliders.filter(slider => slider.type === "book").map(slider => (
                  <View style={styles.gallery} key={slider.id}>
                      <View style={styles.galleryTitle}>
                          <Text style={styles.galleryTitleText}>{slider.label}</Text>
                      </View>
                      <BookSlider
                        books={slider.bookIds.map(bookId => props.books.find(book => book.id === bookId)).filter(book => book !== undefined)}
                      />
                  </View>
                ))}
            </View>

            {props.giftBannerTitle ?
                <ImageBackground source={giftBannerImage} style={styles.giftBannerContainer}>
                    <View style={styles.giftBanner}>
                        <Text style={styles.giftBannerTitle}>{props.giftBannerTitle}</Text>
                        <Text style={styles.giftBannerContent}>
                            {props.giftBannerContent}
                        </Text>
                        <View style={styles.giftBannerLinksContainer}>
                            <Button style={styles.introButton} to="/kingitus">Kingi pakett!</Button>
                            <Link textStyle={styles.introLink} textHoverStyle={styles.introLinkHover}
                                  onPress={() => history.push("/kingitus")}>Lunasta kinkekood</Link>
                        </View>
                    </View>
                </ImageBackground>
                : null
            }

            <View style={styles.instructions}>
                <div ref={instructionsRef} style={{position: 'absolute', top: -TOP_MENU_HEIGHT}} />
                <Text style={styles.instructionsTitle}>Kuidas raamatuid kuulata?</Text>
                <View>
                    <View style={styles.instructionBlocksContainer}>
                        <View style={styles.instructionBlock}>
                            <IconButton style={styles.instructionIcon} name="person" />
                            <Text style={styles.instructionTitle}>
                                Liitu paketiga
                            </Text>
                            <Text style={styles.instructionText}>
                                <Link to="/registreerumine" textStyle={styles.instructionLink}
                                      textHoverStyle={styles.instructionLinkHover}>
                                    Liitu paketiga
                                </Link> ja kuula 2 nädalat tasuta! Prooviperioodi lõppedes on paketi kuutasu 6,99€,
                                mille eest saad kuulata ühe raamatu kuus.
                                Kui tahad rohkem kuulata, siis kuupaketiga on kõik ülejäänud raamatud 50% soodsamad!
                            </Text>
                        </View>
                        <View style={styles.instructionBlock}>
                            <IconButton style={styles.instructionIcon} name="mobile" />
                            <Text style={styles.instructionTitle}>
                                Kuula veebilehel või äpis
                            </Text>
                            <Text style={styles.instructionText}>
                                Raamatuid võid kuulata arvutis, tahvlis või telefonis. Lae alla Digireadi äpp:&nbsp;
                                <Link
                                  blank external
                                  to="https://play.google.com/store/apps/details?id=ee.digiread.app"
                                  textStyle={styles.instructionLink}
                                  textHoverStyle={styles.instructionLinkHover}
                                >
                                    Google Playst
                                </Link>
                                <Text style={styles.instructionText}> või </Text>
                                <Link
                                    blank external
                                    to="https://apps.apple.com/us/app/digiread/id1570619175"
                                    textStyle={styles.instructionLink}
                                    textHoverStyle={styles.instructionLinkHover}
                                >
                                    App Store'ist.
                                </Link>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.instructionBlocksContainer}>
                        <View style={styles.instructionBlock}>
                            <IconButton style={styles.instructionIcon} name="play" />
                            <Text style={styles.instructionTitle}>
                                Leia soovitud raamat ja asu kuulama
                            </Text>
                            <Text style={styles.instructionText}>
                                Meie valikusse lisandub pidevalt uusi teoseid nii Eesti autoritelt kui ka tõlkeraamatuid.
                            </Text>
                        </View>
                        <View style={styles.instructionBlock}>
                            <IconButton style={styles.instructionIcon} name="heavy" />
                            <Text style={styles.instructionTitle}>
                                Internett ja äpp
                            </Text>
                            <Text style={styles.instructionText}>
                                Raamatute kuulamiseks veebis ja allalaadimiseks vajad kas mobiilset internetti või wifit. Äppi laetud raamatuid saad kuulata ka ilma internetiühenduseta.
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

            <ImageBackground source={footerImage} style={styles.footerImage}>
                <View style={styles.footer}>
                    <Text style={styles.footerTitle}>{props.footerTitle}{isTestPeriodOver ? '' : '*'}</Text>
                    <Text style={styles.footerContent}>
                        {props.footerContent}
                    </Text>
                    <View style={styles.footerButtonContainer}>
                        <Button style={styles.introButton} to="/registreerumine">kuula veebist</Button>
                        <Button
                          to="https://play.google.com/store/apps/details?id=ee.digiread.app"
                          style={styles.introButton}
                          blank
                          external
                        >
                            kuula äpist
                        </Button>
                        <Button
                            to="https://apps.apple.com/us/app/digiread/id1570619175"
                            style={styles.introButton}
                            blank
                            external
                        >
                            kuula ios äpist
                        </Button>
                    </View>
                    <Text style={styles.introSubText}>
                        {isTestPeriodOver ? '' : '* Testperioodi lõpuni 30.09.2020!'}
                    </Text>
                </View>
                </ImageBackground>
        </View>
    )
}

const mapStateToProps = (state) => {
    return {
        introTitle: state.blocks.intro.title,
        introContent: state.blocks.intro.content,
        giftBannerTitle: state.blocks.giftBanner?.title,
        giftBannerContent: state.blocks.giftBanner?.content,
        promoTitle: state.blocks.promo.title,
        promoContent: state.blocks.promo.content,
        footerTitle: state.blocks.footer.title,
        footerContent: state.blocks.footer.content,
        books: state.products.filter(book => !book.hasOwnProperty('isVisible') || book.isVisible),
    }
}

export default connect(mapStateToProps, null)(LandingScreen);

const useStylesheet = () => {
    const {width} = useDimensions()
    const isSmall = width < 500

    return StyleSheet.create({
        introButton: {
            flexGrow: 1,
            marginHorizontal: 5,
            textAlign: isSmall ? "center" : null,
            width: isSmall ? "30vh" : null,
            marginTop: isSmall ? "2vh" : null,
        },
        container: {
            flex: 1,
            backgroundColor: '#fff',
        },
        intro: {
            paddingHorizontal: '10%',
            paddingVertical: '5%',
            backgroundColor: 'rgba(0,0,0,0.4)',
        },
        giftBanner: {
            paddingHorizontal: '5%',
            paddingVertical: '5%',
            backgroundColor: 'rgba(0,0,0,0.4)',
            width: "100%",
            justifyContent: "flex-end"
        },
        giftBannerContainer: {
            display: "flex",
            flexDirection: "row",
        },
        giftBannerTitle: {
            fontSize: isSmall ? 50 : 70,
            lineHeight: isSmall ? 50 : 70,
            fontFamily: 'Aino-Headline',
            color: '#fff',
            textAlign: "right"
        },
        giftBannerContent: {
            color: '#fff',
            fontSize: 18,
            fontFamily: 'Aino-Regular',
            paddingTop: 150,
            paddingBottom: 20,
            textAlign: "right"
        },
        giftBannerLinksContainer: {
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: isSmall ? null : 'row',
        },
        introImage: {

        },
        introTitle: {
            fontSize: isSmall ? 50 : 70,
            lineHeight: isSmall ? 50 : 70,
            fontFamily: 'Aino-Headline',
            color: '#fff',
        },
        introContent: {
            color: '#fff',
            fontSize: 18,
            fontFamily: 'Aino-Regular',
            paddingTop: 150,
            paddingBottom: 20,
        },
        introLinksContainer: {
            alignItems: isSmall ? 'center' : 'flex-end',
            justifyContent: 'flex-start',
            flexDirection: isSmall ? null : 'row',
        },
        introLink: {
            marginLeft: 10,
            width: '100%',
            color: 'white',
            textDecorationLine: 'underline',
        },
        introLinkHover: {
            color: 'rgb(200,200,200)',
            textDecorationLine: 'none',
        },
        introSubText: {
            marginTop: 20,
            marginBottom: 20,
            color: 'white',
        },
        promoBackground: {

        },
        promo: {
            paddingHorizontal: '5%',
            paddingTop: '5%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        promoTitle: {
            fontSize: 48,
            lineHeight: 56,
            fontFamily: 'Aino-Headline',
            color: 'rgb(37, 37, 37)',
            width: 'auto',
            paddingHorizontal: 0,
            paddingVertical: '2vh',
            textAlign: 'center',
        },
        promoContent: {
            width: 'auto',
            textAlign: 'center',
            fontSize: 19,
            lineHeight: 24,
            fontFamily: 'Aino-Regular',
            paddingVertical: '2vh',
            color: 'rgb(37, 37, 37)',
        },
        gallery: {
            width: '100%',
            paddingHorizontal: isSmall ? 25 : 50,
            paddingBottom: 50,
        },
        galleryTitle: {
            //marginHorizontal: 10,
            //marginLeft: 0,
            flexDirection: 'row',
            alignSelf: 'flex-start',
            marginBottom: 20,
            borderWidth: 0,
            borderRadius: 2,
            paddingVertical: '.35em',
            //paddingHorizontal: '1.2em',
            backgroundColor: 'transparent',

        },
        galleryTitleText: {
            fontSize: 18,
            fontFamily: 'Aino-Bold',
            color: 'rgb(37, 37, 37)',
            textTransform: 'uppercase',
        },
        instructions: {
            paddingHorizontal: '3%',
            paddingBottom: '5%',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopWidth: 1,
            borderColor: 'rgb(37, 37, 37)',
        },
        instructionsTitle: {
            fontSize: 48,
            lineHeight: 56,
            fontFamily: 'Aino-Headline',
            color: 'rgb(37, 37, 37)',
            paddingVertical: '3%',
            textAlign: 'center',
        },
        instructionBlocksContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        instructionBlock: {
            width: isSmall ? '100%' : '50%',
            maxWidth: 400,
            paddingHorizontal: '2%',
            paddingVertical: '2%',
        },
        instructionIcon: {
            width: '128px',
            height: '128px',
            justifyContent: 'center',
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',

        },
        instructionTitle: {
            textAlign: 'center',
            textTransform: 'uppercase',
            fontFamily: 'Aino-Bold',
            fontSize: 18,
            paddingVertical: 20,
        },
        instructionText: {
            textAlign: 'center',
            fontFamily: 'Aino-Regular',
            fontSize: 16,
        },
        instructionLink: {
            color: 'rgb(102,102,102)',
            fontFamily: 'Aino-Regular',
            fontSize: 16,
            textDecorationLine: 'underline',
            //textShadowColor: 'rgba(0, 0, 0, 1)',
            //textShadowOffset: {width: 0, height: 0},
            //textShadowRadius: 1,
        },
        instructionLinkHover: {
            textDecorationLine: 'none',
            color: 'rgb(37,37,37)',
        },
        footerImage: {

        },
        footer: {
            paddingHorizontal: '6%',
            paddingVertical: '5%',
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
        footerTitle: {
            fontSize: 50,
            lineHeight: 50,
            fontFamily: 'Aino-Headline',
            color: '#fff',
            textAlign: 'center',
            paddingHorizontal: 50,
            paddingTop: 200,
            paddingBottom: 20,
            textShadowColor: 'rgba(0, 0, 0, 1)',
            textShadowOffset: {width: 0, height: 0},
            textShadowRadius: 3,
        },
        footerContent: {
            fontSize: 18,
            lineHeight: 18,
            fontFamily: "Aino-Regular",
            color: 'white',
            width: 'auto',
            textAlign: 'center',
            marginTop: '40px',
            marginBottom: '40px',
            textShadowColor: 'rgba(0, 0, 0, 1)',
            textShadowOffset: {width: 0, height: 0},
            textShadowRadius: 3,
        },
        footerButtonContainer: {
            display: 'flex',
            flexDirection: isSmall ? null : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 20,
            paddingBottom: 20,
        }
    })
}
