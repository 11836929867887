import React from "react";
import {useSelector} from 'react-redux';
import Modal from './Modal';
import AudioPlayerScreen from "../screens/AudioPlayerScreen";
import {View} from "react-native";

const AudioPlayerModal = props => {
    const player = useSelector(state => state.player)
    const isOpen = player.isOpen
    return (
        <Modal style={{margin: 0}} isVisible={isOpen}>
            {isOpen ? <AudioPlayerScreen player={player} /> : <View />}
        </Modal>
    )
}

export default AudioPlayerModal
