import React, {useEffect, useRef, useState} from "react";
import {Audio} from "expo-av";
import {useSelector} from "react-redux";
import {StyleSheet} from "react-native";
import {Text, View} from "react-native-web-ui-components";
import {noop} from "lodash";
import IconButton from "../IconButton";
import {Mixpanel} from "../../utils/mixpanel";
import {selectDlUrl} from "../../redux/config/configSelectors";
import {TrackPosition} from "./AudioPlayer";
import {showMessage} from "react-native-flash-message";

const MiniAudioPlayer = props => {
    const {
        author,
        title,
        bookId,
        sampleFile,
        volume: initialVolume = 1.0,
        rate: initialRate = 1.0,
        position: initialPosition = 0,
    } = props;
    const playbackInstance = useRef(new Audio.Sound()).current;
    const [isPlaying, setIsPlaying] = useState(false);
    const [position, setPosition] = useState(initialPosition);
    let seeking = useRef(false).current;
    let positionUpdateTime = useRef(Date.now()).current;
    const dlUrl = useSelector(selectDlUrl);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        _loadAudio();
        return async () => {
            await playbackInstance.unloadAsync();
        };
    }, []);

    const _loadAudio = async () => {
        _unloadAudio().then(noop).catch(e => console.log(e))

        try {
            const source = {
                uri: `${dlUrl}/samples/${bookId}/${sampleFile}`,
            }
            const initialStatus = {
                shouldPlay: isPlaying,
                initialVolume,
                initialRate,
            }
            playbackInstance.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate)
            await playbackInstance.loadAsync(source, initialStatus, false)
        } catch (e) {
            console.log(e)
        }
    }

    const _unloadAudio = async () => {
        return await playbackInstance.unloadAsync()
    }

    const _onPlaybackStatusUpdate = status => {
        if (!seeking && status.isPlaying) {
            const position = Math.round(status.positionMillis / 1000);
            setPosition(position);
        }
    }

    const _handlePlayPause = async () => {
        let isError = false;
        if (isPlaying) {
            try {
                Mixpanel.track("Sample listening paused", {"bookId": bookId, "author": author, "title": title});
                await playbackInstance.pauseAsync();
            } catch(e) {
                showMessage({message:"Audio faili viga"});
                console.log(e)
                isError=true
            }
        } else {
            try {
                Mixpanel.track("Sample listening started", {"bookId": bookId, "author": author, "title": title});
                await playbackInstance.playAsync();
            } catch(e) {
                showMessage({message:"Audio faili viga"});
                console.log(e)
                isError=true;
            }
        }
        if (isError) {
            setIsPlaying(false);
            setPosition(0)
        } else{
            setIsPlaying(!isPlaying)
        }
    }

    const _onSeeking = ([position]) => {
        try {
            seeking = true
            setPosition(position)
        } catch(e) {
            showMessage({message:"Audio faili viga"});
            console.log(e)
        }

}

    const _onSeek = ([position]) => {
        try {
            playbackInstance.setPositionAsync(position * 1000, {
                toleranceMillisBefore: 0,
                toleranceMillisAfter: 0
            }).catch(e => console.log(e)).then(() => {
                seeking = false
                positionUpdateTime = Date.now()
            })
            seeking = false
        } catch(e) {
            showMessage({message:"Audio faili viga"});
            console.log(e)
        }
}

    return (
        <View style={styles.container}>
            <IconButton
              name={isPlaying ? 'pause' : 'play'}
              size={60}
              onPress={_handlePlayPause}
            />
            {
                (isPlaying || position !== 0) ?
                <TrackPosition
                  position={position}
                  duration={60 * 10}
                  onSeeking={_onSeeking}
                  onSeek={_onSeek}
                /> : <Text style={styles.buttonText}>Kuula näidisklippi</Text>
            }
        </View>
    )
}

export default MiniAudioPlayer

const styles = StyleSheet.create({
    container: {
        paddingTop: 10,
    },
    buttonText: {
        textTransform: "uppercase",
        textDecorationLine: "none",
        textAlign: "center",
        fontSize: 14,
        lineHeight: 18,
        marginTop: 10,
        fontFamily: "Aino-Bold",
    },
})
