import * as React from 'react';
import {Text, View, Title} from "react-native-web-ui-components";

export default function PackagesScreen() {
    return (
        <View>
            <Text><Title id="title" type="text">Paketid</Title></Text>
        </View>
    );
}
