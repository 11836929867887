import React from "react";
import {StyleSheet} from "react-native";
import {View} from "react-native-web-ui-components";
import Link from "./Link";

import Backward from "../assets/icons/backward.svg";
import Book from "../assets/icons/book.svg";
import Close from "../assets/icons/close.svg";
import Download from "../assets/icons/download.svg";
import Forward from "../assets/icons/forward.svg";
import Heart from "../assets/icons/heart.svg";
import Heavy from "../assets/icons/heavy.svg";
import Left from "../assets/icons/left.svg";
import Mobile from "../assets/icons/mobile.svg";
import Pause from "../assets/icons/pause.svg";
import Person from "../assets/icons/person.svg";
import Play from "../assets/icons/play.svg";
import Settings from "../assets/icons/settings.svg";
import Share from "../assets/icons/share.svg";
import Text from "react-native-web-ui-components/Text";

const components = {
    backward: Backward,
    book: Book,
    close: Close,
    download: Download,
    forward: Forward,
    heart: Heart,
    heavy: Heavy,
    left: Left,
    mobile: Mobile,
    pause: Pause,
    person: Person,
    play: Play,
    settings: Settings,
    share: Share,
}

const IconButton = props => {
    const {
        containerStyle,
        style,
        hoverStyle,
        iconStyle,
        iconHoverStyle,
        name,
        to,
        onPress,
        size = 60,
        title,
        ...rest
    } = props
    const Component = components[name]
    const widthHeight = {width: size, height: size + (title ? 14 : 0)}

    return (
        <View style={[styles.container, containerStyle]}>
            {(to || onPress) ?
                <Link
                    style={[styles.view, widthHeight, style]}
                    hoverStyle={[styles.viewHover, hoverStyle]}
                    textStyle={[styles.icon, iconStyle]}
                    textHoverStyle={[styles.iconHover, iconHoverStyle]}
                    to={to}
                    onPress={onPress}
                    {...rest}
                >
                    <Component width="100%" height="100%" />
                    {title ? (
                        <View style={styles.titleContainer}>
                            <Text style={styles.title}>{title}</Text>
                        </View>
                    ) : <View />}
                </Link>
                :
                <View style={[styles.view, widthHeight, style]} {...rest}>
                    <Component width="100%" height="100%" />
                    {title ? (
                        <View style={styles.titleContainer}>
                            <Text style={styles.title}>{title}</Text>
                        </View>
                    ) : <View />}
                </View>
            }
        </View>
    )
}

export default IconButton

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignSelf: 'center',
    },
    view: {
        opacity: 1,
    },
    viewHover: {
        opacity: 0.6,
    },
    icon: {

    },
    iconHover: {

    },
    titleContainer: {

    },
    title: {
        fontSize: 14,
        marginTop: 3,
        marginBottom: 1.5,
        backgroundColor: 'transparent',
        textAlign: 'center',
    },
})
