import React, {useEffect, useState} from "react";
import {Image, Text, View} from "react-native-web-ui-components";
import {useDispatch, useSelector} from "react-redux";
import {StyleSheet} from "react-native";
import Button from "../components/Button";
import PaymentProxy from "../components/PaymentProxy";
import {Mixpanel} from "../utils/mixpanel";
import {selectSubscriptionMonthlyTokensCount} from "../redux/config/configSelectors";
import {useHistory, useParams} from "../routing";
import axios from "axios";
import {SET_USER_DATA} from "../redux/actions";
import {selectUser} from "../redux/reducers";

const creditCardImage = {uri: require('../assets/images/credit_card.svg')};

export const CreditCardPaymentScreen = () => {
    const [startPayment, setStartPayment] = useState(false);
    const [user, setUser] = useState(false);
    const subscriptionMonthlyTokensCount = useSelector(selectSubscriptionMonthlyTokensCount);
    const details = useSelector(selectUser);
    const dispatch = useDispatch();
    const {endpoint, token} = useParams();
    const history = useHistory();

    const isCardChange = endpoint === "change-card-details";
    const isTrialPayment = endpoint === "start-trial";
    const userToken = token === "web" ? details?.apiToken : token;

    if (!userToken) {
        history.push("/login", {redirect: `/kaardimakse/${endpoint}/${token}`});
    }

    const freePaymentInfo = {
        name: "Kaardiandmete uuendamine",
        recurringTitle: "Kaardiandmete uuendamine.",
        recurringDescription: "Kaardiandmete uuendamiseks teostame 0€ makse."
    };

    const startTrialInfo = {
        name: "Prooviperioodi alustamine",
        recurringTitle: "Prooviperioodi alustamine",
        recurringDescription: "Prooviperioodiga alustamiseks teostame 0€ makse. Edaspidi maksab pakett 6.99€/kuus."
    };

    const paymentInfo = {
        name: "Digiread liikmetasu",
        recurringTitle: "Digiread rakenduse liikmetasu",
        recurringDescription: "Liikmetasu on 6.99€ kuus."
    };

    useEffect(() => {
        if (token !== "web") {
            // A precaution for app users since another account could be logged in the web
            dispatch({
                type: SET_USER_DATA,
                payload: null,
            });
        }
    }, []);

    const initiatePayment = () => {
        axios.get(process.env.API2_URL + '/user', {headers: {"Api-Token": userToken}})
            .then(({data}) => {
                dispatch({
                    type: SET_USER_DATA,
                    payload: data,
                });
                Mixpanel.track("Subscription payment initiated");
                setUser(data);
                setStartPayment(true);
            });
    };

    const getPaymentInfo = () => {
        return isCardChange ? freePaymentInfo : isTrialPayment ? startTrialInfo : paymentInfo;
    }

    return (
        <React.Fragment>
            <View style={styles.container}>
                <View style={styles.infoBox}>
                    <Image
                        fixed
                        style={styles.optionImage}
                        source={creditCardImage}
                    />
                    <View style={styles.callToActionContainer}>
                        {!isCardChange && !isTrialPayment && <Text style={styles.callToActionText}>
                            Kas oled valmis liituma meie kuupaketiga, millega saad
                            kuulata <b>{subscriptionMonthlyTokensCount}</b> raamatut <b>6.99€</b> eest kuus?
                        </Text>}
                        {isCardChange && <Text style={styles.callToActionText}>
                            Tegemist on 0€ maksega, millega saate muuta oma kaardiandmeid.
                        </Text>}
                        {isTrialPayment && <Text style={styles.callToActionText}>
                            <b>Prooviperioodi alustamine</b>
                            <br/>
                            <br/>
                            Tee 0€ makse, millega alustad oma kahenädalast prooviperioodi.
                            <br/><br/>
                            14 päeva jooksul saad kuulata <b>{subscriptionMonthlyTokensCount}</b> raamatu tasuta. Edaspidi maksab pakett 6.99€ kuus.
                        </Text>}
                    </View>
                    <Button type="dark" onPress={initiatePayment}>
                        {isCardChange || isTrialPayment ? "Sisesta kaardiandmed" : "Liitu paketiga"}
                    </Button>
                </View>
            </View>
            <PaymentProxy start={startPayment} user={user} endpoint={endpoint}
                          paymentInfo={getPaymentInfo()}/>
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        paddingHorizontal: '0.5em',
        justifyContent: 'center',
        alignItems: 'center',
    },
    callToActionContainer: {
        paddingHorizontal: '1.5em',
        paddingBottom: '0.625em',
    },
    callToActionText: {
        fontSize: '1em',
        textAlign: 'center'
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
})
