import React, {useEffect} from "react";
import {showMessage} from "react-native-flash-message";
import {Mixpanel} from "../utils/mixpanel";
import axios from "axios";
import {SET_USER_DATA} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {StyleSheet} from "react-native";
import {Image, Text, View} from "react-native-web-ui-components";
import {selectUser} from "../redux/reducers";

const creditCardImage = {uri: require('../assets/images/credit_card.svg')}

const SubscriptionPurchaseSuccessScreen = () => {
    const dispatch = useDispatch();
    const details = useSelector(selectUser);

    useEffect(() => {
        Mixpanel.track("Successful subscription purchase");
        showMessage({
            message: "Täname ostu eest, pakett on aktiveeritud. Kui soovid jätkata kuulamist äpis, siis sulge veebilehitseja.",
            type: "success",
        });
        axios.get(process.env.API2_URL + '/user')
            .then(({data}) => {
                dispatch({
                    type: SET_USER_DATA,
                    payload: data,
                });
        }).catch(e => console.log(e));
    }, []);

    return (
        <React.Fragment>
            <View style={styles.container}>
                <View style={styles.infoBox}>
                    <Image
                        fixed
                        style={styles.optionImage}
                        source={creditCardImage}
                    />
                    <View style={styles.callToActionContainer}>
                        <Text style={styles.callToActionText}>
                            <b>Täname, sinu pakett on aktiivne!</b>
                            <br/>
                            <br/>
                            {details?.trialActive && `Tasuta prooviperiood lõpeb ${details?.subscriptionExpiresAt ?? '-'}. `}
                            Kui soovid jätkata äpiga, siis sulge veebilehitseja.
                        </Text>
                    </View>
                </View>
            </View>
        </React.Fragment>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        justifyContent: 'center',
        alignItems: 'center',
    },
    callToActionContainer: {
        marginTop: '1em',
        paddingHorizontal: '1.5em',
        paddingBottom: '0.625em',
    },
    callToActionText: {
        fontSize: '1em',
        textAlign: 'center'
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
});

export default SubscriptionPurchaseSuccessScreen;
