import { ADD_FETCHED_PAGES } from "../actions";

export default function pagesReducer(state = [], action) {
    switch (action.type) {
        case ADD_FETCHED_PAGES:
            return action.payload;
        default:
            return state;
    }
}
