import {
    PLAYER_CLOSE,
    PLAYER_OPEN,
    PLAYER_SET_INDEX,
    PLAYER_SET_POSITION,
    PLAYER_SET_RATE,
    PLAYER_SET_VOLUME,
} from "../actions"

const initialState = {
    isOpen: false,
    positions: [],
}

export default function playerReducer(state = initialState, action) {
    const positions = state.positions ? state.positions : []
    let newState = {...state, positions}
    switch (action.type) {
        case PLAYER_OPEN:
            const {rate = 1} = state
            const merge = action.bookId === state.bookId ? state : {volume: 1, rate}
            const positions = state.positions ? state.positions : []
            return {...merge, isOpen: true, bookId: action.bookId, positions}

        case PLAYER_CLOSE:
            return {...state, isOpen: false}

        case PLAYER_SET_VOLUME:
            return {...state, volume: action.payload}

        case PLAYER_SET_RATE:
            return {...state, rate: action.payload}

        case PLAYER_SET_INDEX:
            newState.positions[action.payload.bookId] = {
                index: action.payload.index,
                position: 0,
            }
            return newState

        case PLAYER_SET_POSITION:
            newState.positions[action.payload.bookId] = {
                index: action.payload.index,
                position: action.payload.position,
            }
            return newState

        default:
            return state;
    }
}
