import React from 'react';
import {Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import {useDimensions} from "react-native-responsive-ui/src/index";

export const Brand = () => {
    const {width} = useDimensions();
    const isWide = width > 900;
    return (
        <View style={styles(isWide).container}>
            <Text style={styles(isWide).text} type="menuLink">Digiread</Text>
        </View>
    );
}

const styles = isWide => StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: isWide ? 10 : 0,
    },
    text: {
        fontFamily: 'Changa-Bold',
        fontSize: 40,
        paddingRight: isWide ? 20 : 5,
        paddingLeft: isWide ? 10 : 5,
    }
})
