import React from 'react';
import Menu from './menu/Menu';
import {View} from "react-native-web-ui-components";
import {MediaQuery} from "react-native-responsive-ui/src";
import {TOP_MENU_HEIGHT} from "../constants";
import HamburgerMenu from "./menu/HamburgerMenu";

export default class Header extends React.Component {
    render() {
        return (
            <View style={styles.container}>
                <MediaQuery minWidth={1080}>
                    <Menu />
                </MediaQuery>
                <MediaQuery maxWidth={1079}>
                    <HamburgerMenu />
                </MediaQuery>
            </View>
        )
    }
}

const styles = {
    container: {
        position: 'fixed',
        display: 'block',
        zIndex: 9999,
        backgroundColor: 'white',
        width: '100%',
        height: TOP_MENU_HEIGHT,
        overflow: 'visible',
        borderBottomWidth: 1,
        borderBottomColor: '#C3C3C3',
        borderBottomStyle: 'solid'
    }
}
