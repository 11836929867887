import { LIBRARY_FETCH_START, LIBRARY_FETCH_COMPLETE } from "../actions";

export default function libraryLoadingReducer(state = true, action) {
    switch (action.type) {
        case LIBRARY_FETCH_START:
            return true;
        case LIBRARY_FETCH_COMPLETE:
            return false;
        default:
            return state;
    }
}
