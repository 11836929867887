import React from "react";
import axios from "axios";
import useSWR from "swr";
import {useDispatch, useSelector} from "react-redux";
import {removeBooksFromCart} from "../redux/cart/cartActions";
import {useLogout} from "./User";
import {showMessage} from "react-native-flash-message";
import * as Sentry from "@sentry/react";

export function useLibrary() {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const logout = useLogout();

    const fetcher = url => user ? axios.get(url).then(res => {
        const data = res.data;
        const bookIds = data.map(book => book.id);
        dispatch(removeBooksFromCart(bookIds));
        return data;
    }).catch((err => {
        if (axios.isAxiosError(err)) {
            if (err?.response?.status === 401 || err?.response?.status === 403) {
                Sentry.captureException(err,  {
                    tags: {
                        customEvent: "library_exception",
                        isActedOn: "true"
                    },
                });
                showMessage({
                    message: "Teie sessioon on aegunud, palun logige uuesti sisse.",
                    type: "info",
                });
                logout();
            } else {
                Sentry.captureException(err,  {
                    tags: {
                        customEvent: "library_exception",
                        isActedOn: "false"
                    },
                });
            }
        }
    })) : Promise.resolve([]);
    const {data, error, mutate} = useSWR(process.env.API2_URL + "/library", fetcher);

    return {
        library: data,
        isLoading: !error && !data,
        isError: error,
        mutateLibrary: mutate,
    };
}

export function withLibrary(WrappedComponent) {
    return props => {
        const { library, isLoading, isError } = useLibrary()
        return <WrappedComponent library={library} isLibraryLoading={isLoading} {...props} />
    }
}

export default {
    useLibrary,
    withLibrary,
}
