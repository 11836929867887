import React from 'react'
import Animated from 'react-native-reanimated'
import {View} from 'react-native-web-ui-components'

const AnimatedView = React.forwardRef((props, ref) => {
    return (
        <View onRef={ref} {...props} />
    )
})

export default Animated.createAnimatedComponent(AnimatedView)
