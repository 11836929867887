import React, {useEffect, useRef, useState} from "react";
import {Audio} from "expo-av";
import {useDispatch} from "react-redux";
import {StyleSheet} from "react-native";
import {useDimensions} from "react-native-responsive-ui/src";
import {Text, View} from "react-native-web-ui-components";
import {noop} from "lodash";
import IconButton from "../IconButton";
import Slider from "../Slider";
import {
    PLAYER_CLOSE,
    PLAYER_SET_INDEX,
    PLAYER_SET_POSITION,
    PLAYER_SET_RATE,
    PLAYER_SET_VOLUME,
} from "../../redux/actions";
import useStyles from "../../hooks/useStyles";
import Settings from "./Settings";
import Description from "./Description";
import CoverImage from "./CoverImage";
import {Mixpanel} from "../../utils/mixpanel";
import {showMessage} from "react-native-flash-message";
import axios from "axios";

const TrackInfo = ({current, total, styles}) => (
    <View style={styles.trackInfo}>
        <Text style={styles.trackInfoText}>Peatükk {current + 1} / {total}</Text>
    </View>
)

const getTimeDisplay = (seconds) => {
    if (typeof seconds !== 'number' || isNaN(seconds) || seconds === Infinity) return '-'
    const min = Math.floor(seconds / 60)
    const sec = seconds % 60
    return (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec
}

const BOOK_READING_REPORT_MILLIS = 30000;

export const TrackPosition = props => {
    const {
        position,
        duration,
        onSeeking,
        onSeek,
        sliderSize,
    } = props;
    const styles = useStyles(createStyles);

    return (
      <View style={styles.trackSeekContainer}>
          <View style={styles.trackSeekSliderContainer}>
              <Slider
                sliderSize={sliderSize}
                values={[position]}
                max={duration}
                onValuesChange={onSeeking}
                onValuesChangeFinish={onSeek}
              />
            </View>
            <View style={styles.trackPositionInfoContainer}>
                <Text style={styles.trackPositionInfoText}>
                    {getTimeDisplay(position)} / {getTimeDisplay(duration)}
                </Text>
            </View>
        </View>
    )
}

const AudioPlayer = props => {
    const {
        author,
        title,
        imageUri,
        description,
        links,
        headers,
        bookId,
        autoPlay = false,
        index: initialIndex = 0,
        volume: initialVolume = 1.0,
        rate: initialRate = 1.0,
        position: initialPosition = 0,
    } = props
    const playbackInstance = useRef(new Audio.Sound()).current;
    const [currentIndex, setCurrentIndex] = useState(initialIndex);
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [volume, setVolume] = useState(initialVolume);
    const [rate, setRate] = useState(initialRate);
    const [position, setPosition] = useState(initialPosition);
    const [isBuffering, setIsBuffering] = useState(false);
    const [playbackStartedAt, setPlaybackStartedAt] = useState(undefined);
    const [isSettingsVisible, setSettingsVisibility] = useState(false);
    const [lastReadingReportTime, setLastReadingReportTime] = useState(0);
    const dispatch = useDispatch();
    const styles = useStyles(createStyles);
    const {width} = useDimensions();
    const [duration, setDuration] = useState(0);
    let seeking = useRef(false).current;
    let positionUpdateTime = useRef(Date.now()).current;
    let positionReportTime = useRef(Date.now()).current;

    useEffect(() => {
        _loadAudio().then(() => {
            if (props.index === currentIndex) {
                playbackInstance.setPositionAsync(position * 1000, {
                    toleranceMillisBefore: 0,
                    toleranceMillisAfter: 0,
                });
            }
        });
        return async () => {
            await playbackInstance.unloadAsync()
        }
    }, [currentIndex])

    useEffect(() => {
        if (playbackStartedAt && lastReadingReportTime - playbackStartedAt > 1000) {
            setPlaybackStartedAt((currentValue) => {
                axios.post(process.env.API2_URL + "/reading", {
                    bookId,
                    playbackSpeed: rate,
                    startedAtEpoch: currentValue,
                    endedAtEpoch: lastReadingReportTime,
                    source: "WEB"
                });
                return Date.now();
            });
        }
    }, [lastReadingReportTime, rate]);

    const _loadAudio = async () => {
        _unloadAudio().then(noop).catch(e => console.log(e))

        try {
            const source = {
                uri: links[currentIndex],
                headers,
            }
            const initialStatus = {
                shouldPlay: isPlaying,
                volume,
                rate,
            }
            playbackInstance.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate)
            await playbackInstance.loadAsync(source, initialStatus, false)
        } catch (e) {
            console.log(e)
        }
    }

    const _unloadAudio = async () => {
        return await playbackInstance.unloadAsync()
    }

    const _onPlaybackStatusUpdate = status => {
        if (!seeking && status.isLoaded && !Number.isNaN(status.durationMillis) && duration === 0) {
            setDuration(Math.round(status.durationMillis / 1000));
        }
        if (!seeking && status.isPlaying) {
            const position = Math.round(status.positionMillis / 1000);
            const time = Date.now();
            setPosition(position);
            if (time >= positionUpdateTime + 5000) {
                dispatch({
                    type: PLAYER_SET_POSITION,
                    payload: {
                        index: currentIndex,
                        position,
                        bookId,
                    },
                });
                positionUpdateTime = time;
            }
            if (time >= positionReportTime + BOOK_READING_REPORT_MILLIS) {
                setLastReadingReportTime(time);
                positionReportTime = time;
            }
        }
        setIsBuffering(status.isBuffering)

        if (status.didJustFinish && currentIndex === links.length - 1 && playbackStartedAt) {
            // Finished the book lets report and reset
            axios.post(process.env.API2_URL + "/reading", {
                bookId,
                startedAtEpoch: playbackStartedAt,
                endedAtEpoch: Date.now(),
                playbackSpeed: rate,
                source: "WEB"
            }).finally(() => setPlaybackStartedAt(null));
        }

        if (status.didJustFinish && currentIndex < links.length - 1) {
            _handleNextTrack().then(noop)
        }
    }

    const _handlePlayPause = async () => {
        let isError = false;
        if (isPlaying) {
            try {
                Mixpanel.track("Book listening paused", {"bookId": bookId, "author": author, "title": title, "chapter": currentIndex});
                await playbackInstance.pauseAsync();
                if (playbackStartedAt) {
                    await axios.post(process.env.API2_URL + "/reading", {
                        bookId,
                        startedAtEpoch: playbackStartedAt,
                        playbackSpeed: rate,
                        endedAtEpoch: Date.now(),
                        source: "WEB"
                    }).finally(() => {
                        setPlaybackStartedAt(null);
                    });
                }
            } catch(e) {
                showMessage({message:"Audio faili viga"});
                console.log(e)
                isError=true;
            }
        } else {
            try {
                Mixpanel.track("Book listening started", {"bookId": bookId, "author": author, "title": title, "chapter": currentIndex});
                await playbackInstance.playAsync();
                setPlaybackStartedAt(Date.now());
            } catch(e) {
                showMessage({message:"Audio faili viga"});
                console.log(e)
                isError=true;
            }
    }
        if (isError) {
            setIsPlaying(false);
            setPosition(0)
        } else {
            setIsPlaying(!isPlaying)
        }
    }

    const _handlePreviousTrack = async () => {
        const index = currentIndex ? currentIndex - 1 : 0
        setCurrentIndex(index)
        setPosition(0)
        Mixpanel.track("Previous chapter start", {"bookId": bookId, "author": author, "title": title, "chapter": index});
        dispatch({
            type: PLAYER_SET_INDEX,
            payload: {
                index,
                bookId,
            },
        })
    }

    const _handleNextTrack = async () => {
        const index = currentIndex < links.length - 1 ? currentIndex + 1 : 0
        setCurrentIndex(index)
        setPosition(0);
        setDuration(0);
        Mixpanel.track("Next chapter start", {"bookId": bookId, "author": author, "title": title, "chapter": index});
        dispatch({
            type: PLAYER_SET_INDEX,
            payload: {
                index,
                bookId,
            },
        })
    }

    const _onSeeking = ([position]) => {
        try {
            seeking = true
            setPosition(position)
        } catch(e) {
            showMessage({message:"Audio faili viga"});
            console.log(e)
        }
    }

    const _onSeek = ([position]) => {
        try {
            playbackInstance.setPositionAsync(position * 1000, {
                toleranceMillisBefore: 0,
                toleranceMillisAfter: 0
            }).catch(e => console.log(e)).then(() => {
                seeking = false
                dispatch({
                    type: PLAYER_SET_POSITION,
                    payload: {
                        index: currentIndex,
                        position,
                        bookId,
                    },
                })
                positionUpdateTime = Date.now()
            })
            seeking = false
        } catch(e) {
            showMessage({message:"Audio faili viga"});
            console.log(e)
        }
    }

    const _onChangeVolume = ([volume]) => {
        playbackInstance.setVolumeAsync(volume).then(() => {
            setVolume(volume)
            dispatch({
                type: PLAYER_SET_VOLUME,
                payload: volume,
            })
        })
    }

    const _onChangeSpeed = async ([speed]) => {
        if (isPlaying && Date.now() - playbackStartedAt > 300) {
            await axios.post(process.env.API2_URL + "/reading", {
                bookId,
                startedAtEpoch: playbackStartedAt,
                playbackSpeed: rate,
                endedAtEpoch: Date.now(),
                source: "WEB"
            }).finally(() => {
                setPlaybackStartedAt(Date.now());
                setLastReadingReportTime(Date.now());
            });
        }

        playbackInstance.setRateAsync(speed, true, Audio.PitchCorrectionQuality.High).then(() => {
            setRate(speed)
            dispatch({
                type: PLAYER_SET_RATE,
                payload: speed,
            })
        })
    }

    const _close = () => {
        if (width <= MAXIMAL_PLAYER_ABOVE && isSettingsVisible) {
            setSettingsVisibility(false)
        } else {
            dispatch({
                type: PLAYER_CLOSE,
            });
        }
    }

    const _toggleSettings = () => {
        setSettingsVisibility(!isSettingsVisible);
    };

    const sliderSize = width > MAXIMAL_PLAYER_ABOVE ? 18 : 25;

    return (
      <View style={styles.container}>
          <View style={styles.headerButtonContainer}>
              <IconButton
                name="left"
                size={69}
                onPress={_close}
                containerStyle={{flexGrow: 1}}
                style={styles.headerButton}
                iconStyle={styles.headerButtonIcon}
              />
              <IconButton
                name="settings"
                size={69}
                onPress={_toggleSettings}
                style={[styles.headerButton, styles.settingsButton]}
                iconStyle={styles.headerButtonIcon}
              />
              <IconButton
                name="close"
                size={69}
                onPress={_close}
                style={styles.headerButton}
                iconStyle={styles.headerButtonIcon}
              />
          </View>

          <View style={styles.playerContainer}>
              <View style={{flexDirection: "row", flex: 1}}>
                  <Description style={styles.descriptionContainer} previewLines={DESCRIPTION_NUMBER_OF_LINES}>
                      {description}
                  </Description>
                  <CoverImage style={styles.coverImageContainer} uri={imageUri}/>
                  <Settings
                    sliderSize={sliderSize}
                    style={styles.settingsContainer}
                    visible={width > MAXIMAL_PLAYER_ABOVE || isSettingsVisible}
                    volume={volume}
                    rate={rate}
                    onChangeVolume={_onChangeVolume}
                    onChangeSpeed={_onChangeSpeed}
                  />
              </View>
          </View>

          <View style={styles.trackContainer}>
              <View style={styles.titleContainer}>
                  <Text style={[styles.titleText, styles.author]}>{author} - </Text>
                  <Text style={styles.titleText}>{title}</Text>
              </View>
              <TrackInfo
                styles={styles}
                current={currentIndex}
                total={links.length}
              />
              <TrackPosition
                position={position}
                duration={duration}
                onSeeking={_onSeeking}
                onSeek={_onSeek}
                sliderSize={sliderSize}
              />
              <View style={styles.controls}>
                  <IconButton
                    name="backward"
                    size={48}
                    style={styles.control}
                    onPress={_handlePreviousTrack}
                  />
                  <IconButton
                    name={isPlaying ? "pause" : "play"}
                    size={72}
                    style={styles.control}
                    onPress={_handlePlayPause}
                  />
                  <IconButton
                    name="forward"
                    size={48}
                    style={styles.control}
                    onPress={_handleNextTrack}
                  />
              </View>
          </View>
      </View>
    )
}

export default AudioPlayer

const createStyles = ({width}) => StyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingVertical: 10,
        flexBasis: '100%',
    },
    headerButtonContainer: {
        flex: 0,
        flexBasis: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerButton: {
        padding: 10,
    },
    headerButtonIcon: {

    },
    playerContainer: {
        flex: 1,
        flexBasis: '100%',
        marginHorizontal: 20,
    },
    descriptionContainer: {
        display: width > MINIMAL_PLAYER_BELOW ? 'flex' : 'none',
        flex: 1,
    },
    coverImageContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    settingsContainer: {
        //display: width > MAXIMAL_PLAYER_ABOVE ? 'flex' : 'none',
        position: width > MAXIMAL_PLAYER_ABOVE ? 'relative' : 'absolute',
        flex: 1,
        marginLeft: width > MAXIMAL_PLAYER_ABOVE ? 20 : 0,
        width: '100%',
        height: '100%',
    },
    settingsButton: {
        display: width <= MAXIMAL_PLAYER_ABOVE ? 'flex' : 'none',
    },
    trackContainer: {
        flex: 0,
        flexBasis: 'auto',
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: 'white',
    },
    titleContainer: {
        flexDirection: 'row',
    },
    titleText: {
        width: 'auto',
        fontSize: width > MINIMAL_PLAYER_BELOW ? 36 : 30,
        lineHeight: width > MINIMAL_PLAYER_BELOW ? 40 : 36,
        fontFamily: 'Aino-Headline',
    },
    author: {
        display: width > MINIMAL_PLAYER_BELOW ? 'inline' : 'none',
    },
    trackInfo: {

    },
    trackInfoText: {
        fontSize: 16,
        lineHeight: 20,
        fontFamily: 'Aino-Headline',
    },
    trackSeekContainer: {

    },
    trackSeekSliderContainer: {
        marginVertical: 10,
    },
    trackPositionInfoContainer: {

    },
    trackPositionInfoText: {
        fontSize: 16,
        lineHeight: 20,
        fontFamily: 'Aino-Headline',
        marginVertical: 5,
    },
    controls: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    control: {

    },
    playlistContainer: {
        flex: 1,
        minWidth: 300,
    },
    playlistTitle: {
        paddingBottom: 40,
        marginBottom: 20,
        borderBottomWidth: 3,
    },
    playlistTitleText: {
        fontSize: 20,
        lineHeight: 24,
        fontFamily: 'Aino-Headline',
    },
    playlistBody: {
        flex: 1,
    },
    playlistItem: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    playlistItemText: {
        fontSize: 16,
        lineHeight: 20,
        fontFamily: 'Aino-Headline',
    },
    playlistActiveItemText: {
        color: 'blue',
    },
})

const MAXIMAL_PLAYER_ABOVE = 960
const MINIMAL_PLAYER_BELOW = 640
const DESCRIPTION_NUMBER_OF_LINES = 6
