export {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams,
    withRouter,
    useLocation
} from 'react-router-dom';
