import {UPDATE_CONFIG} from "./configActions";

export const configReducer  = (state = {}, action) => {
    if (state === null) {
        // TODO: FIX STORAGE VERSIONING
        state = {};
    }

    switch (action.type) {
        case UPDATE_CONFIG:
            return action.config;
        default:
            return state;
    }
}
