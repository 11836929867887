import { SAVE_LIBRARY } from "../actions";

export default function libraryReducer(state = null, action) {
    switch (action.type) {
        case SAVE_LIBRARY:
            return action.payload;
        default:
            return state;
    }
}
