import React, {useState} from 'react';
import { View, Text, TouchableWithoutFeedback, StyleSheet } from 'react-native';
import {
    TabView,
    SceneMap,
} from 'react-native-tab-view';
import Animated from 'react-native-reanimated';
import { Ionicons } from '@expo/vector-icons';
import LibraryScreen from "./LibraryScreen";
import BooksScreen from "./BooksScreen";
import ProfileScreen from "./ProfileScreen";

const routes = [
    {key: 'library', title: 'Raamaturiiul', icon: 'ios-albums', activeIcon: 'ios-albums'},
    {key: 'books', title: 'Raamatud', icon: 'ios-albums', activeIcon: 'ios-albums'},
    {key: 'profile', title: 'Profiil', icon: 'ios-person', activeIcon: 'ios-person'},
]

export default props => {
    const [index, setIndex] = useState(0)

    const _renderItem = ({position}) => {
        return ({route, index}) => {
            const inputRange = routes.map((_, i) => i)
            const activeOpacity = Animated.interpolate(position, {
                inputRange,
                outputRange: inputRange.map(i => i === index ? 1 : 0)
            })
            const inactiveOpacity  = Animated.interpolate(position, {
                inputRange,
                outputRange: inputRange.map(i => i === index ? 0 : 1)
            })

            return (
                <View style={styles.tab}>
                    <Animated.View style={[styles.item, { opacity: inactiveOpacity }]}>
                        <Ionicons
                            name={route.icon}
                            size={26}
                            style={[styles.icon, styles.inactive]}
                        />
                        <Text style={[styles.label, styles.inactive]}>{route.title}</Text>
                    </Animated.View>
                    <Animated.View
                        style={[styles.item, styles.activeItem, { opacity: activeOpacity }]}
                    >
                        <Ionicons
                            name={route.activeIcon}
                            size={26}
                            style={[styles.icon, styles.active]}
                        />
                        <Text style={[styles.label, styles.active]}>{route.title}</Text>
                    </Animated.View>
                </View>
            )
        }
    }

    const _renderTabBar = props => (
        <View style={styles.tabBar}>
            {routes.map((route, index) => {
                return (
                    <TouchableWithoutFeedback
                        key={route.key}
                        onPress={() => props.jumpTo(route.key)}
                    >
                        {_renderItem(props)({ route, index })}
                    </TouchableWithoutFeedback>
                );
            })}
        </View>
    )

    const _renderScene = SceneMap({
        library: LibraryScreen,
        books: LibraryScreen,//BooksScreen,
        profile: ProfileScreen,
    })

    return (
        <TabView
            navigationState={{index, routes}}
            renderScene={_renderScene}
            renderTabBar={_renderTabBar}
            tabBarPosition="bottom"
            onIndexChange={index => setIndex(index)}
        />
    )
}

const styles = StyleSheet.create({
    tabBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#fafafa',
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: 'rgba(0, 0, 0, .2)',
    },
    item: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 4.5,
    },
    activeItem: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    active: {
        color: '#0084ff',
    },
    inactive: {
        color: '#939393',
    },
    icon: {
        height: 26,
        width: 26,
    },
    label: {
        fontSize: 10,
        marginTop: 3,
        marginBottom: 1.5,
        backgroundColor: 'transparent',
    },
})
