import { ADD_FETCHED_PRODUCTS } from "../actions"

export default function booksReducer(state = [], action) {
    switch (action.type) {
        case ADD_FETCHED_PRODUCTS:
            return action.payload.sort((a, b) => a.id < b.id ? 1 : -1)
        default:
            return state
    }
}
