import React, {useRef} from 'react';
import { TextInput as UITextInput } from 'react-native-web-ui-components';

export default props => {
    const {
        style,
        focusStyle,
        onRef,
        autoFocus,
        ...rest
    } = props
    const ref = useRef(null)
    return (
        <UITextInput
            onRef={component => {
                onRef && onRef(component)
                ref.current = component
            }}
            style={autoFocus ? focusStyle : style}
            onFocus={() => ref.current && ref.current.setNativeProps({style: focusStyle})}
            onBlur={() => ref.current && ref.current.setNativeProps({style: style})}
            placeHolderTextColor={placeHolderTextColor}
            autoFocus={autoFocus}
            {...rest}
        />
    )
}

const placeHolderTextColor = 'rgb(102, 102, 102)'
