import {StyleSheet} from "react-native";
import {useDimensions} from "react-native-responsive-ui/src";

export const useFormStyles = () => {
    const {width} = useDimensions()
    const inputContainerStyles = width > 500 ? {
        flex: 1,
    } : {
        width: '100%',
    }

    return StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        form: {
            width: 500,
            maxWidth: '90%',
            paddingHorizontal: 40,
            paddingVertical: 40,
            marginBottom: 50,
            marginTop: 50,
            marginLeft: 'auto',
            marginRight: 'auto',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 10,
            },
            shadowOpacity: 0.3,
            shadowRadius: 20,
            elevation: 20,
        },
        header: {
            paddingHorizontal: 30,
            paddingVertical: 20,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            borderColor: 'rgba(0,0,0,0.1)',
            backgroundColor: 'rgb(248,248,248)',
        },
        headerText: {
            fontSize: 25,
            fontFamily: 'Aino-Headline',
            color: 'rgb(102, 102, 102)',
        },
        fieldSet: {
            paddingTop: 20,
            paddingHorizontal: 30,
            marginRight: -20,
        },
        section: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        inputContainer: {
            marginRight: 20,
            marginBottom: 20,
            ...inputContainerStyles
        },
        input: {
            display: 'flex',
            borderColor: 'rgb(102, 102, 102)',
            color: 'rgb(102, 102, 102)',
            width: '100%',
            height: 39,
            paddingVertical: 8,
            paddingHorizontal: 10,
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 4,
            backgroundColor: '#fff',
            fontSize: 16,
            fontFamily: 'Aino-Regular',
        },
        inputFocus: {
            borderColor: '#2da5da',
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 4,
        },
        iconContainer: {
            position: 'absolute',
            right: width > 500 ? 30 : 10,
            top: 5,
            width: 24,
            height: 29,
        },
        icon: {
            paddingLeft: 5,
            fontSize: 16,
            lineHeight: 29,
            borderColor: 'rgb(102, 102, 102)',
            borderLeftWidth: 1,
            color: 'rgb(102, 102, 102)',
            textAlign: 'center',
        },
        footer: {
            marginLeft: 30,
            marginRight: 10,
            marginBottom: 20,
            flexDirection: 'row',
        },
        button: {
            width: '100%',
            borderColor: 'rgb(102, 102, 102)',
        },
        buttonHover: {

        },
        buttonText: {
            color: 'rgb(102, 102, 102)',
            textAlign: 'center',
        },
        buttonTextHover: {

        },
    })
}
