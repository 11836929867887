import React, {useState} from 'react'
import {StyleSheet, ScrollView}from 'react-native'
import {Text, View} from 'react-native-web-ui-components'
import Button from '../Button'

const Content = props => {
    const [full, setFull] = useState(false)
    const styles = props.styles

    return <React.Fragment>
        <View style={styles.textContainer}>
            <Text numberOfLines={full ? undefined : props.lines} style={styles.text}>{props.children}</Text>
        </View>
        <Button type="dark" onPress={() => {setFull(!full)}}>{full ? "Sulge" : "Loe lähemalt"}</Button>
    </React.Fragment>
}

export default function Description(props) {
    const {
        previewLines,
        children,
        ...rest
    } = props
    return (
        <View {...rest}>
            <View style={styles.content}>
                <ScrollView>
                    <Content styles={styles} lines={previewLines}>
                        {children}
                    </Content>
                </ScrollView>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    content: {
        flex: 1,
        marginRight: 20,
        //marginTop: 60,
    },
    textContainer: {
        paddingBottom: 20,
    },
    text: {
        flexShrink: 1,
        fontSize: 16,
        lineHeight: 20,
        fontFamily: 'Aino-Headline',
    },
})
