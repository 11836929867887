import React, {useEffect} from "react";
import {useFormStyles} from "../components/forms/styles";
import {Text, TextInput, View} from "react-native-web-ui-components";
import {ValidationError} from "../components/forms";
import {FontAwesome} from "@expo/vector-icons";
import Button from "../components/Button";
import {useForm} from "react-hook-form";
import axios from "axios";
import {showMessage} from "react-native-flash-message";
import {useDimensions} from "react-native-responsive-ui/src/index";
import {StyleSheet} from "react-native";
import {useHistory} from "../routing.web";

const ForgotPasswordScreen = () => {
    const styles = useFormStyles();
    const {register, setValue, handleSubmit, errors} = useForm();
    const {width} = useDimensions();
    const history = useHistory();
    const submit = data => {
        axios.post(process.env.API2_URL + '/forgot-password', {
            email: data.email,
        }).then(() => {
            showMessage({
                message: 'Saatsime lingi parooli muutmiseks teie emailile.',
                type: 'success',
            })
            history.push("/");
        }).catch(error => {
            showMessage({
                message: 'Parooli muutmise algatamine ebaõnnestus!',
                type: 'danger',
            })
        })
    };

    useEffect(() => {
        register({name: 'email'}, {required: true});
    }, [register])

    const customStyles = StyleSheet.create({
        title: {
            marginBottom: 20
        }
    });

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View style={styles.fieldSet}>
                    <Text style={[styles.headerText, customStyles.title]}>Unustasid salasõna?</Text>
                    <Text>Sisesta meiliaadress. Saadame meilile lingi salasõna muutmiseks.</Text>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                autoFocus
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Meiliaadress"
                                keyboardType="email-address"
                                onChangeText={email => setValue('email', email, true)}
                                returnKeyType="next"
                                blurOnSubmit={false}
                                onSubmitEditing={handleSubmit(submit)}
                            />
                            {errors.email?.type === 'required' &&
                            <ValidationError>See väli on kohustuslik.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="envelope" style={styles.icon}/>
                        </View>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Button
                        auto={width > 500}
                        type="dark"
                        style={styles.button}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={handleSubmit(submit)}
                    >
                        Saada
                    </Button>
                </View>
            </View>
        </View>
    );
};

export default ForgotPasswordScreen;
