import React from 'react'
import {StyleSheet, Platform} from "react-native";
import HoverableView from "./HoverableView";

const Link = props => {
    const {
        style,
        hoverStyle,
        textStyle,
        textHoverStyle,
        to,
        ...rest
    } = props

    return (
        <HoverableView
            style={[styles.view, style]}
            hoverStyle={[styles.viewHover, hoverStyle]}
            textStyle={[styles.text, to ? styles.empty : styles.textLink, textStyle]}
            textHoverStyle={[styles.textHover, textHoverStyle]}
            to={to}
            {...rest}
        />
    )
}

export default Link

const styles = StyleSheet.create({
    empty: {},
    textLink: Platform.OS === 'web' ? {
        cursor: 'pointer',
    } : {

    },
    view: {

    },
    viewHover: {

    },
    text: {

    },
    textHover: {

    },
})
