import React, {useEffect, useState} from 'react'
import {Image as RNImage} from 'react-native'
import {Image, View} from 'react-native-web-ui-components'

export default function CoverImage({style, uri}) {
    const [imageDimensions, setImageDimensions] = useState({width: 1, height: 1})
    const [viewDimensions, setViewDimensions] = useState({width: 1, height: 1})

    useEffect(() => {
        RNImage.getSize(uri, (width, height) => setImageDimensions({width, height}))
    }, [])

    let width, height, ratio
    if (imageDimensions.height > imageDimensions.width) {
        height = viewDimensions.height
        ratio = viewDimensions.height / imageDimensions.height
        width = ratio * imageDimensions.width
    } else {
        width = viewDimensions.width
        ratio = viewDimensions.width / imageDimensions.width
        height = ratio * imageDimensions.height
    }

    return (
        <View style={style} onLayout={event => setViewDimensions({width, height} = event.nativeEvent.layout)}>
            <Image
                fixed
                style={{width, height}}
                source={{uri}}
            />
        </View>
    )
}
