import {useLocation} from "../routing";
import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import ErrorBoundary from "./error/ErrorBoundary";

export const PageWrapper = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'ADD_PATH_TO_HISTORY',
            payload: location.pathname,
        });
    }, [location]);

    return (
        <div>
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
        </div>
    );
}