import React, {useEffect} from "react";
import {useHistory} from "../routing";
import {showMessage} from "react-native-flash-message";
import {Mixpanel} from "../utils/mixpanel";

const GiftCardPurchaseSuccessScreen = () => {
    const history = useHistory();

    useEffect(() => {
        Mixpanel.track("Successful gift card purchase");
        showMessage({
            message: "Täname ostu eest, kingituse detailid saadetakse sulle e-kirjaga.",
            type: "success",
        });
        history.push("/");
    }, []);

    return null;
};

export default GiftCardPurchaseSuccessScreen;
