import {ADD_PATH_TO_HISTORY} from "../actions";

export default function lastPathReducer(state = [], action) {
    switch (action.type) {
        case ADD_PATH_TO_HISTORY:
            const newHistory = [
                ...state,
                action.payload
            ];
            if (newHistory.length > 5) {
                newHistory.shift();
            }
            return newHistory;
        default:
            return state;
    }
}