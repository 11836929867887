import { ADD_FETCHED_BLOCKS } from "../actions";

const blocks = ['intro', 'promo', 'footer'];

const defaultState = blocks.reduce((blocks, id) => {
    blocks[id] = {
        title: '',
        content: ''
    };
    return blocks;
}, {});

export default function blocksReducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_FETCHED_BLOCKS:
            return {...action.payload};
        default:
            return state;
    }
}
