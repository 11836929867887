import React from 'react';
import { View, Text } from 'react-native-web-ui-components';
import {StyleSheet} from "react-native";

const ValidationError = props => {
    return (
        <View style={styles.container}>
            <Text style={styles.content}>{props.children}</Text>
        </View>
    );
}

export default ValidationError

const styles = StyleSheet.create({
    container: {

    },
    content: {
        color: 'red',
    },
})
