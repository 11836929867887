import mixpanel from "mixpanel-browser";


let env_check = process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        if (env_check) {
            try {
                if (!mixpanel.has_opted_out_tracking()) {
                    mixpanel.identify(id);
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    alias: (id) => {
        if (env_check) {
            try {
                if (!mixpanel.has_opted_out_tracking()) {
                    mixpanel.alias(id);
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    track: (name, props) => {
        if (env_check) {
            try {
                if (!mixpanel.has_opted_out_tracking()) {
                    mixpanel.track(name, props);
                }
            } catch (e) {
                console.log(e);
            }
        }
    },
    people: {
        set: (props) => {
            if (env_check) {
                try {
                    if (!mixpanel.has_opted_out_tracking()) {
                        mixpanel.people.set(props);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
    },
    reset: () => {
        if (env_check) {
            try {
                if (!mixpanel.has_opted_out_tracking()) {
                    mixpanel.reset();
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
};

export let Mixpanel = actions;
