import React, {useEffect, useState} from "react";
import FlashMessage from "react-native-flash-message";
import {useFonts} from "@use-expo/font";
import AppLoading from 'expo-app-loading';
import {Router} from "./routing";
import Store from "./components/Store";
import Root from "./components/Root";
import useCacheBuster from "./hooks/useCacheBuster";
import {Audio} from "expo-av";
import {Alert, AlertContext} from "./components/Alert";
import {GdprConsent} from "./components/GdprConsent";
import {useDispatch, useSelector} from "react-redux";
import * as Sentry from "@sentry/react";
import {selectSentryConfig} from "./redux/config/configSelectors";
import {useUser} from "./modules/User";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import {AsyncStorage} from "react-native-web";
import ErrorBoundary from "./components/error/ErrorBoundary";
import {SET_USER_DATA} from "./redux/actions";

try {
  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    "api_host": "https://api-eu.mixpanel.com",
    opt_out_tracking_by_default: true,
  }, "");
} catch (e) {
  console.log("Could not initialize Mixpanel");
}


export default function App() {
  return (
    <Store>
      <AppInternal/>
    </Store>
  );
}

const updateSentryUserData = (sentryInitialized, user) => {
  if (sentryInitialized) {
    if (user.isLoggedIn) {
      Sentry.configureScope(scope => {
        scope.setUser(user);
      });
    } else {
      Sentry.configureScope(scope => {
        scope.setUser(null);
      });
    }
  }
};

const initializeSentry = (sentryInitialized, sentryConfig, setSentryInitialized) => {
  if (!sentryInitialized && sentryConfig) {
    Sentry.init({
      dsn: sentryConfig.sentryDsn,
      environment: sentryConfig.sentryEnvironment,
      normalizeDepth: 3,
    });
    setSentryInitialized(true);
  }
};

const AppInternal = () => {
  const [fontsLoaded] = useFonts({
    "AbrilFatface-Regular": require("./assets/fonts/AbrilFatface-Regular.otf"),
    "Aino-Regular": require("./assets/fonts/Aino-Regular.otf"),
    "Aino-Bold": require("./assets/fonts/Aino-Bold.otf"),
    "Aino-Headline": require("./assets/fonts/Aino-Headline.otf"),
    "MyriadPro-Regular": require("./assets/fonts/MyriadPro-Regular.otf"),
    "Changa-Regular": require("./assets/fonts/Changa-Regular.ttf"),
    "Changa-Bold": require("./assets/fonts/Changa-Bold.ttf"),
  });
  const {loading, isLatestVersion, refreshCacheAndReload} = useCacheBuster();
  const [alertProps, setAlertProps] = useState({show: false});
  const sentryConfig = useSelector(selectSentryConfig);
  const [sentryInitialized, setSentryInitialized] = useState(false);

  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      initializeSentry(sentryInitialized, sentryConfig, setSentryInitialized);
    } catch (e) {
      console.error("Could not initialize Sentry");
    }
  }, [sentryConfig, sentryInitialized])

  useEffect(() => {
    try {
      updateSentryUserData(sentryInitialized, user);
    } catch (e) {
      console.error("Could not update Sentry user data");
    }
  }, [sentryInitialized, user]);

  useEffect(() => {
    if (!user) return
    axios.get(process.env.API2_URL + '/user').then(res => {
        dispatch({
          type: SET_USER_DATA,
          payload: res.data,
        })
    })
  }, []);

  if (loading || !fontsLoaded || !sentryInitialized) {
    return <AppLoading/>;
  }

  if (!isLatestVersion) {
    refreshCacheAndReload();
    return null;
  }

  Audio.setAudioModeAsync({
    allowsRecordingIOS: false,
    interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
    playsInSilentModeIOS: true,
    interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DUCK_OTHERS,
    shouldDuckAndroid: true,
    staysActiveInBackground: true,
    playThroughEarpieceAndroid: true,
  });

  return (
    <AlertContext.Provider value={{setAlertProps}}>
      <Router>
        <ErrorBoundary>
          <GdprConsent/>
        </ErrorBoundary>
        <Root/>
        {alertProps.show && <Alert {...alertProps}/>}
        <FlashMessage position="top"/>
      </Router>
    </AlertContext.Provider>
  );
};

axios.interceptors.request.use(function (config) {
  return AsyncStorage.getItem("apiToken").then(apiToken => {
    if (!apiToken) {
      return config;
    }
    return {...config, headers: {...config.headers, "Api-Token": apiToken}};
  });
}, function (error) {
  return Promise.reject(error);
});
