import React, {useEffect, useState} from "react";
import {Checkbox, Text, View} from "react-native-web-ui-components";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {showMessage} from "react-native-flash-message";
import {FontAwesome} from "@expo/vector-icons";
import axios from "axios";
import {useHistory} from "../routing";
import {TextInput, ValidationError} from "../components/forms";
import {email, matchValue} from "../components/forms/validators";
import Button from "../components/Button";
import {useDimensions} from "react-native-responsive-ui/src";
import {SET_USER_DATA} from "../redux/actions";
import {useFormStyles} from "../components/forms/styles";
import Link from "../components/Link";
import {StyleSheet} from "react-native";
import {Mixpanel} from "../utils/mixpanel";

const RegisterScreen = () => {
    const { register, setValue, handleSubmit, errors, getValues, setError } = useForm();
    const [lastNameInput, setLastNameInput] = useState(null);
    const [emailInput, setEmailInput] = useState(null);
    const [passwordInput, setPasswordInput] = useState(null);
    const [passwordConfirmInput, setPasswordConfirmInput] = useState(null);
    const [userTermsCheckbox, setUserTermsCheckbox] = useState(false);
    const dispatch = useDispatch();
    const styles = useFormStyles();
    const {width} = useDimensions();

    useEffect(() => {
        register({ name: 'firstName'}, { required: true });
        register({ name: 'lastName'}, { required: true });
        register({ name: 'email'}, { required: true, ...email });
        register({ name: 'password'}, { required: true, minLength: 6 });
        register({ name: 'passwordConfirm'}, { required: true, validate: {matchPassword: matchValue('password', getValues)} });
        register({name: 'userTermsCheckbox'}, {required: true});
    }, [register])

    const history = useHistory();
    const submit = async (data) => {
        axios.post(process.env.API2_URL + '/register', {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            password: data.password,
        }).then(data => {
            showMessage({
                message: 'Kasutaja registreerimine õnnestus.',
                type: 'success',
            })
            dispatch({
                type: SET_USER_DATA,
                payload: data.data,
            });
            Mixpanel.track("Register");
            history.push('/');
        }).catch(error => {
            const data = error?.response?.data;
            if (data?.message === "E-mail address in use") {
                setError('email', 'unique');
            } else {
                showMessage({
                    message: 'Kasutaja registreerimine ebaõnnestus teadmata põhjusel.',
                    type: 'danger',
                })
            }
        })
    }

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View style={styles.fieldSet}>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Eesnimi"
                                autoFocus
                                onChangeText={name => setValue('firstName', name, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {lastNameInput.focus()}}
                                blurOnSubmit={false}
                            />
                            {errors.firstName?.type === 'required' &&
                                <ValidationError>See väli on kohustuslik.</ValidationError>}
                        </View>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Perekonnanimi"
                                onRef={setLastNameInput}
                                onChangeText={name => setValue('lastName', name, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {emailInput.focus()}}
                                blurOnSubmit={false}
                            />
                            {errors.lastName?.type === 'required' &&
                                <ValidationError>See väli on kohustuslik.</ValidationError>}
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Meiliaadress"
                                onRef={setEmailInput}
                                keyboardType="email-address"
                                //autoCompleteType="email"
                                onChangeText={email => setValue('email', email, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {passwordInput.focus()}}
                                blurOnSubmit={false}
                            />
                            {errors.email?.type === 'required' &&
                                <ValidationError>See väli on kohustuslik.</ValidationError>}
                            {errors.email?.type === 'pattern' &&
                                <ValidationError>Palun sisesta korrektne meiliaadress.</ValidationError>}
                            {errors.email?.type === 'unique' &&
                                <ValidationError>Selle aadressiga konto on juba registreeritud.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="envelope" style={styles.icon} />
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Parool"
                                onRef={setPasswordInput}
                                secureTextEntry
                                //autoCompleteType="password"
                                onChangeText={password => setValue('password', password, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {passwordConfirmInput.focus()}}
                                blurOnSubmit={false}
                            />
                            {getValues().length < 6 &&
                                <Text>Minimaalne parooli pikkus on 6 tähemärki.</Text>
                            }
                            {errors.password?.type === 'required' &&
                                <ValidationError>See väli on kohustuslik.</ValidationError>}
                            {errors.password?.type === 'minLength' &&
                                <ValidationError>Minimaalne parooli pikkus on 6 tähemärki.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="lock" style={styles.icon} />
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Korda parooli"
                                onRef={setPasswordConfirmInput}
                                onSubmitEditing={() => {userTermsCheckbox.focus()}}
                                secureTextEntry
                                //autoCompleteType="password"
                                onChangeText={password => setValue('passwordConfirm', password, true)}
                            />
                            {errors.passwordConfirm?.type === 'required' &&
                                <ValidationError>See väli on kohustuslik.</ValidationError>}
                            {errors.passwordConfirm?.type === 'matchPassword' &&
                                <ValidationError>Paroolid ei kattu.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="lock" style={styles.icon} />
                        </View>
                    </View>
                    <View style={styles.inputContainer}>
                        <View style={customStyles.checkboxRow}>
                            <Checkbox
                                style={customStyles.checkbox}
                                onPress={checked => setValue('userTermsCheckbox', !checked, true)}
                                checked={getValues('userTermsCheckbox')}
                                onRef={setUserTermsCheckbox}
                                onSubmitEditing={handleSubmit(submit)}
                            />
                            <Text style={customStyles.text}>
                                Olen lugenud ja nõustun Digiread <Link textStyle={[customStyles.text, customStyles.link]} textHoverStyle={customStyles.linkHover} external blank to="/kasutustingimused">kasutustingimustega</Link>.
                            </Text>
                        </View>
                        {errors.userTermsCheckbox?.type === 'required' &&
                        <ValidationError>See väli on kohustuslik.</ValidationError>}
                    </View>
                </View>
                <View style={styles.footer}>
                    <Button
                        auto={width > 500}
                        type="dark"
                        style={styles.button}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={handleSubmit(submit)}
                    >
                        Registreeru
                    </Button>
                </View>
            </View>
        </View>
    );
}

const customStyles =  StyleSheet.create({
    checkboxRow: {
        flexDirection: 'row'
    },
    text: {
        fontSize: 16,
        lineHeight: 18,
        textAlignVertical: 'middle'
    },
    link: {
        textDecorationLine: 'underline',
        color: '#666',
    },
    linkHover: {
        color: '#999',
    },
    checkbox: {
        marginRight: 5,
    },
})

export default RegisterScreen
