import React, {useCallback, useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "../routing";
import {selectCartCount} from "../redux/cart/cartSelectors";
import {showMessage} from "react-native-flash-message";
import {Mixpanel} from "../utils/mixpanel";
import {Loading} from "react-native-web-ui-components";
import axios from "axios";

const EmailConfirmedScreen = () => {
    const history = useHistory();
    const cartCount = useSelector(selectCartCount);

    const confirmEmail = useCallback(async () => {
        const params = new URLSearchParams(window.location.search.substring(1));
        const token = params.get("token");
        const isTrial = params.get("trial") === 'true';

        if (token === null) {
            showMessage({
                message: "Viga!",
                type: "danger",
            });
            return;
        }

        try {
            await axios.post(`${(process.env.API2_URL)}/verify`, {token});
            showMessage({
                message: "E-maili aadress on kinnitatud.",
                type: "success",
            });
            Mixpanel.track("Email confirmed", {"cartSize": cartCount});
        } catch (e) {
            showMessage({
                message: "Tekkis viga meiliaadressi kinnitamisega. Palun proovige uuesti.",
                type: "danger"
            });
        }

        if (cartCount > 0) {
            history.push("/ostukorv");
        } else if (isTrial) {
            history.push("/kaardimakse/start-trial/web");
        } else {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        confirmEmail();
    }, [confirmEmail]);

    return <Loading/>;
};

export default EmailConfirmedScreen;
