import React from 'react';
import {Image, Text, View} from "react-native-web-ui-components";
import {connect} from "react-redux";
import {StyleSheet} from "react-native";
import Button from "../components/Button";
import PaymentProxy from "../components/PaymentProxy";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Mixpanel} from "../utils/mixpanel";

const bookImage = {uri: require('../assets/images/book.svg')}
const creditCardImage = {uri: require('../assets/images/credit_card.svg')}

class SubscriptionOrderScreen extends React.Component {
    state = {
        startPayment: false,
    }

    _startPayment() {
        Mixpanel.track("Subscription payment initiated");
        this.setState({
            startPayment: true
        })
    }

    _navigateToBooks() {
        this.props.history.push('/raamatud');
    }

    render() {
        return (
            <React.Fragment>
                <View style={styles.container}>
                    <View style={styles.infoBox}>
                        <Image
                            fixed
                            style={styles.optionImage}
                            source={creditCardImage}
                        />
                        <View style={styles.callToActionContainer}>
                            <Text style={styles.callToActionText}>
                                Kas oled valmis liituma meie kuupaketiga, millega saad
                                kuulata <b>{this.props.subscriptionMonthlyTokensCount}</b> raamatut <b>6.99€</b> eest kuus?
                            </Text>
                        </View>
                        <Button type="dark" onPress={this._startPayment.bind(this)}>
                            Liitu paketiga
                        </Button>
                        <Image
                            fixed
                            style={styles.optionImage}
                            source={bookImage}
                        />
                        <View style={styles.callToActionContainer}>
                            <Text style={styles.callToActionText}>
                                Soovid veel ringi vaadata ning otsida kuulamiseks mõnda üksikraamatut?
                            </Text>
                        </View>
                        <Button type="dark" onPress={this._navigateToBooks.bind(this)}>
                            Sirvi raamatuid
                        </Button>
                    </View>
                </View>
                <PaymentProxy start={this.state.startPayment} user={this.props.user}/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (rootState) => {
    return {
        user: rootState.user,
        subscriptionMonthlyTokensCount: rootState.config?.subscriptionMonthlyTokensCount ?? 2
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(SubscriptionOrderScreen);


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        justifyContent: 'center',
        alignItems: 'center',
    },
    callToActionContainer: {
        paddingHorizontal: '1.5em',
        paddingBottom: '0.625em',
    },
    callToActionText: {
        fontSize: '1em'
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
})
