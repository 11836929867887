import {ADD_BOOK_TO_CART, EMPTY_CART, REMOVE_BOOKS_FROM_CART} from "./cartActions";


export const cartReducer  = (state = [], action) => {
    if (state === null) {
        // TODO: FIX STORAGE VERSIONING
        state = [];
    }

    switch (action.type) {
        case ADD_BOOK_TO_CART:
            if (state.find(bookId => bookId === action.bookId)) {
                return state;
            }
            return [...state, action.bookId];
        case REMOVE_BOOKS_FROM_CART:
            return state.filter(bookId => !action.bookIds.includes(bookId));
        case EMPTY_CART:
            return [];
        default:
            return state;
    }
}
