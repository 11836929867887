import axios from "axios";

const apiUrl = process.env.API2_URL;

export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const fetchConfig = (dispatch) => {
  return axios.get(apiUrl + "/config")
    .then(response => {
      dispatch({
        type: UPDATE_CONFIG,
        config: response.data,
      });
    })
    .catch(error => {
      throw (error);
    });
};
