import React, {useEffect, useState} from "react";
import axios from "axios";
import {Loading} from "react-native-web-ui-components";

function PaymentProxy(props) {
    const [data, setData] = useState(false)

    if (props.start) {
        if (data) {
            return <Payment user={props.user} paymentInfo={props.paymentInfo} data={data} />
        }
        const endpoint = props.endpoint ? "/" + props.endpoint : '/checkout-subscription';
        axios.post(process.env.API2_URL + endpoint, {
            apiToken: props.apiToken,
        }).then(({data}) => {
            setData(data)
        })
        return <Loading />
    }
    return null
}

function Payment(props) {
    useEffect(() => {
        const {user, paymentInfo} = props
        const script = document.createElement('script');
        script.src = props.data.url
        script.addEventListener('load', () => _initPayment({user, paymentInfo, ...props.data}))
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script);
        }
    }, [])
    return null
}

function _initPayment({key, transactionId, amount, user, paymentInfo}) {
    window._onCompletePayment = _completePayment
    window._onCancelPayment = _cancelPayment
    window._transactionId = transactionId
    window.Maksekeskus.Checkout.initialize({
        key: key,
        transaction: transactionId,
        amount: amount,
        currency: 'EUR',
        email: user.email,
        clientName: user.firstName + ' ' + user.lastName,
        locale: 'et',
        name: paymentInfo !== undefined ? paymentInfo.name : 'Digiread liikmetasu',
        recurringTitle: paymentInfo !== undefined ? paymentInfo.recurringTitle : 'Digiread rakenduse liikmetasu',
        recurringDescription: paymentInfo !== undefined ? paymentInfo.recurringDescription : `Liikmetasu on ${amount}€ kuus.`,
        recurringRequired: true,
        completed: '_onCompletePayment',
        cancelled: '_onCancelPayment',
        openOnLoad: true,
        backdropClose: false,
    });
}

function _completePayment(data) {
    axios.post(process.env.API2_URL + '/notify', data).then(_closeWindow)
}

function _cancelPayment() {
    axios.post(process.env.API2_URL + '/notify', {
        json: JSON.stringify({
            message_type: 'payment_return',
            status: 'CANCELLED',
            transaction: {id: window._transactionId},
        }),
    }).then(_closeWindow)
}

function _closeWindow() {
    window.location.reload(true)
}

export default PaymentProxy
