import { ADD_FETCHED_CATEGORIES } from "../actions";

export default function categoriesReducer(state = [], action) {
    switch (action.type) {
        case ADD_FETCHED_CATEGORIES:
            return {...action.payload};
        default:
            return state;
    }
}
