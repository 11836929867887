import React from 'react';
import { StyleSheet } from "react-native";
import {View} from "react-native-web-ui-components";
import HoverableView from "./HoverableView";

export default props => {
    const {
        style = [],
        hoverStyle = [],
        textStyle = [],
        textHoverStyle = [],
        type = 'light',
        children,
        className,
        to,
        disabled = false,
        auto = true,
        ...rest
    } = props;
    const normalizedStyleProps = {
        style: Array.isArray(style) ? style : [style],
        hoverStyle: Array.isArray(hoverStyle) ? hoverStyle : [hoverStyle],
        textStyle: Array.isArray(textStyle) ? textStyle : [textStyle],
        textHoverStyle: Array.isArray(textHoverStyle) ? textHoverStyle : [textHoverStyle],
    }
    const maybeDisabledStyle = disabled ? [styles.common.disabled, styles[type].disabled] : [];
    const maybeDisabledText = disabled ? [styles.common.disabledText, styles[type].disabledText] : [];
    const mergedStyles = {
        style: [styles.common.view, styles[type].view, ...maybeDisabledStyle, ...normalizedStyleProps.style],
        hoverStyle: [styles.common.viewHover, styles[type].viewHover, ...maybeDisabledStyle, ...normalizedStyleProps.hoverStyle],
        textStyle: [styles.common.text, styles[type].text, ...maybeDisabledText, ...normalizedStyleProps.textStyle],
        textHoverStyle: [styles.common.textHover, styles[type].textHover, ...maybeDisabledText, ...normalizedStyleProps.textHoverStyle],
    }
    return (
        <View className={(className ? className + ' ' : '') + 'button-wrapper button-' + (auto ? 'auto' : 'full')}>
            <HoverableView to={to} disabled={disabled} {...mergedStyles} {...rest}>
                {children}
            </HoverableView>
        </View>
    );
}

const styles = {
    common: StyleSheet.create({
        view: {
            borderWidth: 2,
            borderRadius: 2,
            paddingBottom: 5,
            paddingTop: 7,
            paddingHorizontal: 16,
            backgroundColor: 'transparent',
        },
        viewHover: {

        },
        text: {
            textTransform: 'uppercase',
            textDecorationLine: 'none',
            fontSize: 16,
            lineHeight: 18,
            fontFamily: 'Aino-Bold',
        },
        textHover: {

        },
        disabled: {
            borderColor: 'rgba(118, 118, 118, 0.3);',
        },
        disabledText: {
            color: 'rgba(118, 118, 118, 0.3);',
        },
    }),
    light: StyleSheet.create({
        view: {
            borderColor: 'white',
        },
        viewHover: {
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        text: {
            color: 'white',
        },
        textHover: {
            color: 'rgb(37,37,37)',
        },
        disabled: {

        },
        disabledText: {

        },
    }),
    dark: StyleSheet.create({
        view: {
            borderColor: 'black',
        },
        viewHover: {
            borderColor: 'rgb(37,37,37)',
            backgroundColor: 'rgb(37,37,37)',
        },
        text: {
            color: 'rgb(37,37,37)',
        },
        textHover: {
            color: 'white',
        },
        disabled: {

        },
        disabledText: {

        },
    }),
}
