import {useFormStyles} from "../components/forms/styles";
import {useForm} from "react-hook-form";
import {useDimensions} from "react-native-responsive-ui/src/index";
import axios from "axios";
import {showMessage} from "react-native-flash-message";
import React, {useEffect, useState} from "react";
import {Text, TextInput, View} from "react-native-web-ui-components";
import {ValidationError} from "../components/forms";
import {FontAwesome} from "@expo/vector-icons";
import Button from "../components/Button";
import {matchValue} from "../components/forms/validators";
import {useHistory, useParams} from "../routing.web";
import {SET_USER_DATA} from "../redux/actions";
import {useDispatch} from "react-redux";

const ResetPasswordScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [passwordConfirmInput, setPasswordConfirmInput] = useState(null);
    const {token} = useParams();

    const styles = useFormStyles();
    const {register, setValue, handleSubmit, getValues, errors} = useForm();

    const {width} = useDimensions();
    const submit = data => {
        axios.post(process.env.API2_URL + '/reset-password', {
            password: data.password,
            token: token
        }).then(({data}) => {
            showMessage({
                message: 'Parooli muutmine õnnestus!',
                type: 'success',
            });
            dispatch({
                type: SET_USER_DATA,
                payload: data,
            });
            history.replace('/');
        }).catch(error => {
            showMessage({
                message: 'Parooli muutmine ebaõnnestus!',
                type: 'danger',
            })
        })
    };

    useEffect(() => {
        register({name: 'password'}, {required: true, minLength: 6});
        register({name: 'passwordConfirm'}, {
            required: true,
            validate: {matchPassword: matchValue('password', getValues)}
        });
    }, [register])


    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View style={styles.fieldSet}>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Uus parool"
                                secureTextEntry
                                onChangeText={password => setValue('password', password, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {
                                    passwordConfirmInput.focus()
                                }}
                                blurOnSubmit={false}
                            />
                            {getValues().length < 6 &&
                                <Text>Minimaalne parooli pikkus on 6 tähemärki.</Text>
                            }
                            {errors.password?.type === 'required' &&
                            <ValidationError>See väli on kohustuslik.</ValidationError>}
                            {errors.password?.type === 'minLength' &&
                            <ValidationError>Minimaalne parooli pikkus on 6 tähemärki.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="lock" style={styles.icon}/>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Korda parooli"
                                onRef={setPasswordConfirmInput}
                                onSubmitEditing={handleSubmit(submit)}
                                secureTextEntry
                                onChangeText={password => setValue('passwordConfirm', password, true)}
                            />
                            {errors.passwordConfirm?.type === 'required' &&
                            <ValidationError>See väli on kohustuslik.</ValidationError>}
                            {errors.passwordConfirm?.type === 'matchPassword' &&
                            <ValidationError>Paroolid ei kattu.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="lock" style={styles.icon}/>
                        </View>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Button
                        auto={width > 500}
                        type="dark"
                        style={styles.button}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={handleSubmit(submit)}
                    >
                        Muuda
                    </Button>
                </View>
            </View>
        </View>
    );
};

export default ResetPasswordScreen;
