import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet} from 'react-native';
import noop from 'lodash/noop';
import NativeSlider from '@ptomasroos/react-native-multi-slider';
import {View, StylePropType} from "react-native-web-ui-components";
import {withTheme} from 'react-native-web-ui-components/Theme';

const useComponentSize = () => {
    const [size, setSize] = useState({ width: 0, height: 0 })

    const onLayout = event => {
        const { width, height } = event.nativeEvent.layout
        setSize({ width, height })
    }

    return [size, onLayout]
}

const Slider = props => {
    const {
        themeInputStyle,
        style,
        sliderLength,
        sliderSize,
        disabled,
        readonly,
        ...rest
    } = props
    const selectedStyle = StyleSheet.flatten(themeInputStyle.slider)
    const unselectedStyle = StyleSheet.flatten(themeInputStyle.slider)
    const slider = (width) => (
        <NativeSlider
            markerOffsetY={18}
            markerOffsetX={9}
            containerStyle={[styles.container, themeInputStyle.opacity, style]}
            markerStyle={[
                styles.marker,
                {
                    backgroundColor: selectedStyle.color,
                    borderColor: selectedStyle.color,
                    width: sliderSize,
                    height: sliderSize
                },
            ]}
            pressedMarkerStyle={[
                styles.marker,
                {
                    width: sliderSize,
                    height: sliderSize
                }
            ]}
            markerContainerStyle={styles.markerContainer}
            unselectedStyle={[
                styles.track,
                { backgroundColor: unselectedStyle.color },
            ]}
            selectedStyle={[
                styles.selected,
                { backgroundColor: selectedStyle.color },
            ]}
            sliderLength={width || sliderLength}
            enableOne={!(disabled || readonly)}
            enableTwo={!(disabled || readonly)}
            {...rest}
        />
    )
    const [size, onLayout] = useComponentSize()
    return sliderLength ? slider() : (
        <View onLayout={onLayout}>
            {slider(size.width)}
        </View>
    )
}

Slider.propTypes = {
    themeInputStyle: PropTypes.shape().isRequired,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onValuesChange: PropTypes.func,
    onValuesChangeStart: PropTypes.func,
    onValuesChangeFinish: PropTypes.func,
    style: StylePropType,
    sliderLength: PropTypes.number,
    sliderSize: PropTypes.number,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    snapped: PropTypes.bool,
}

Slider.defaultProps = {
    min: 0,
    max: 10,
    step: 1,
    onValuesChange: noop,
    onValuesChangeStart: noop,
    onValuesChangeFinish: noop,
    style: null,
    sliderLength: null,
    sliderSize: 18,
    disabled: false,
    readonly: false,
    snapped: false,
}

export default withTheme('Slider')(Slider)

const styles = StyleSheet.create({
    container: {
        height: 18,
    },
    track: {
        height: 3,
        borderRadius: 32,
        marginTop: 7,
    },
    selected: {
        height: 3,
        borderRadius: 32,
        marginTop: 7,
    },
    markerContainer: {
        width: 30,
        height: 30,
    },
    marker: {
        borderRadius: 100,
        shadowOffset: {
            width: 0,
            height: 0,
        },
    },
})
