import React, {useContext} from "react";
import AwesomeAlert from "react-native-awesome-alerts";

export const Alert = (props) => {

    const commonAlertProps = {
        showProgress: false,
        closeOnTouchOutside: true,
        closeOnHardwareBackPress: true,
        showCancelButton: true,
        showConfirmButton: true,
    };

    const fixedAlertProps = {
        confirmButtonColor: "#3BDD33",
        alertContainerStyle: {position: "fixed", top: 0, left: 0, height: "100%", width: "100%"},
        overlayStyle: {position: "fixed", top: 0, left: 0, height: "100%", width: "100%"},
    };

    const combinedProps = {...commonAlertProps, ...props, ...fixedAlertProps};

    return <AwesomeAlert {...combinedProps}/>;
};

export const AlertContext = React.createContext({});

export const useAlert = (config) => {
    const alertContext = useContext(AlertContext);
    const closeAlert = () => {
        alertContext.setAlertProps({show: false});
    };
    return {
        show: () => {
            alertContext.setAlertProps({
                ...config,
                show: true,
                onDismiss: closeAlert,
                onConfirmPressed: () => {
                    config.onConfirmPressed();
                    closeAlert();
                },
                onCancelPressed: closeAlert,
            });
        },
    };
};
