import React from "react";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import AsyncStorage from "@react-native-community/async-storage";
import thunk from "redux-thunk";
import axios from "axios";
import rootReducer from "../redux/reducers";
import {fetchBlocks, fetchCategories, fetchPages, fetchProducts} from "../redux/actions";
import {fetchConfig} from "../redux/config/configActions";
import * as Sentry from "@sentry/react";

let initialState = {};

const loadState = async () => {
    try {
        const state = await AsyncStorage.getItem("state");
        const parsedState = JSON.parse(state);
        if (!Array.isArray(parsedState.lastPath)) {
            parsedState.lastPath = [];
        }
        initialState = state ? parsedState : null;
    } catch(error) {
        console.log('Error loading state', error);
    }
}

const saveState = async (state) => {
    try {
        AsyncStorage.setItem("state", JSON.stringify(state));
        if (state.user?.apiToken) {
            AsyncStorage.setItem("apiToken", state.user.apiToken);
        } else {
            AsyncStorage.removeItem("apiToken");
        }
    } catch (error) {
        console.log('Error saving state', error);
    }
};

export default class Store extends React.Component {
    state = { loaded: false }

    constructor(props) {
        super(props);
        loadState().then(() => {
            const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

            //console.log('state loaded', initialState);
            this.setAuthorization(initialState);
            let middleware = compose(applyMiddleware(thunk), sentryReduxEnhancer);
            this.store = initialState !== null ? createStore(rootReducer, initialState, middleware) : createStore(rootReducer, middleware);
            this.store.dispatch(fetchBlocks());
            this.store.dispatch(fetchProducts());
            this.store.dispatch(fetchCategories());
            this.store.dispatch(fetchPages());
            this.store.dispatch(fetchConfig);
            this.store.subscribe(() => {
                const state = this.store.getState();
                saveState(state).then(() => {
                    //console.log('state saved', state)
                });
                this.setAuthorization(state)
            })
            this.setState({loaded: true});
        });
    }

    setAuthorization(state) {
        delete axios.defaults.headers.common['Authorization']
        if (state && state.user && state.user.apiToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.user.apiToken
        }
    }

    render() {
        return this.state.loaded ? (
            <Provider store={this.store}>
                {this.props.children}
            </Provider>
        ) : null;
    }
}
