import {selectUser} from "../redux/reducers";
import {StyleSheet, Text, View} from "react-native";
import {useFormStyles} from "../components/forms/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "../routing";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {TouchableOpacity} from "react-native-web";
import {useAlert} from "../components/Alert";
import {SET_USER_DATA} from "../redux/actions";
import {showMessage} from "react-native-flash-message";
import {Mixpanel} from "../utils/mixpanel";
import {selectSubscriptionGracePeriodMonths} from "../redux/config/configSelectors";

export const UserDetailsScreen = () => {
    const history = useHistory();
    const details = useSelector(selectUser);
    const subscriptionGracePeriodMonths = useSelector(selectSubscriptionGracePeriodMonths);
    const dispatch = useDispatch();
    const styles = useFormStyles();
    const [tokenExpiry, setTokenExpiry] = useState();
    const unsubscribeAlert = useAlert({
        title: "Paketi lõpetamine",
        message: "Palun kinnitage, et soovite oma paketi lõpetada",
        cancelText: "Tühista",
        confirmText: "Kinnita",
        confirmButtonColor: "#cb4a4a",
        onConfirmPressed: () => {
            if (details && details.subscriptionActive) {
                axios.post(process.env.API2_URL + `/unsubscribe`).then(({data}) => {
                    dispatch({
                        type: SET_USER_DATA,
                        payload: data,
                    });
                    showMessage({
                        message: 'Teie pakett on lõpetatud. Teenus jääb aktiivseks kuni ' + data.subscriptionExpiresAt,
                        type: 'success',
                        duration: 6000,
                    });
                    Mixpanel.track("Subscription cancelled");
                }).catch(e => console.log(e.message));
            }
        }
    });

    if (!details) {
        history.push("/login");
    }

    const handlePaymentBtnClick = () => {
        history.push(`/kaardimakse/change-card-details/web`);
    }

    const handleSubscriptionBtnClick = () => {
        if (details?.subscriptionExpiresAt) {
            history.push("/paketiost");
        } else {
            history.push("/kaardimakse/start-trial/web");
        }
    }

    const handleGiftCardButtonClick = () => {
        history.push("/kingitus");
    }

    const handleUnsubscribeBtnClick = () => {
        unsubscribeAlert.show();
    }

    const getUserTokenExpiry = async () => {
        return axios.get(`${process.env.API2_URL}/payment-token-expiry`);
    }

    useEffect(() => {
        getUserTokenExpiry()
            .then(result => (setTokenExpiry(result.data)))
            .catch(e => console.log(e.message));
    }, []);

    const renderSection = (title, value) => {
        return (
            <View style={styles.fieldSet}>
                <View style={customStyles.section}>
                    <Text style={customStyles.text}>{title}</Text>
                    <Text style={customStyles.text}>{value}</Text>
                </View>
            </View>
        );
    }

    const renderCtaButton = (text, handler) => {
        return (
            <TouchableOpacity
                style={[customStyles.btnContainer, text === "Lõpeta pakett" ? customStyles.unsubBtnColor : customStyles.changeCardColor]}
                onPress={handler}>
                <Text style={customStyles.btnText}>{text}</Text>
            </TouchableOpacity>
        );
    }

    const getSubscriptionStatus = () => {
        if (details?.trialActive) {
            return "Prooviperiood";
        }

        return details?.subscriptionActive ? "Aktiivne" : "Mitteaktiivne";
    }

    const subscriptionExpiresAt = new Date(details?.subscriptionExpiresAt ?? "2020-01-01");
    const now = new Date();
    const gracePeriodEndDate = new Date(details?.subscriptionExpiresAt ?? "2020-01-01");
    gracePeriodEndDate.setMonth(gracePeriodEndDate.getMonth() + subscriptionGracePeriodMonths);

    const getSubscriptionExpiryTitle = () => {
        if (details?.trialActive) {
            return "Prooviperiood kestab kuni";
        }
        return "Pakett kehtib kuni";
    }

    const subscriptionButtonCtaText = details?.subscriptionExpiresAt ? "Liitu paketiga" : "Alusta prooviperioodi";

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View style={customStyles.centeredContainer}>
                    <Text style={customStyles.title}>Minu konto</Text>
                </View>
                {renderSection("Nimi", details?.firstName + " " + details?.lastName)}
                {renderSection("Meiliaadress", details?.email)}
                <View style={customStyles.extraSpace}/>
                {renderSection("Paketi staatus", getSubscriptionStatus())}
                {renderSection("Kasutamata ühikud", details?.subscriptionTokens || 0)}
                {subscriptionExpiresAt > now ? (
                    renderSection(getSubscriptionExpiryTitle(), details?.subscriptionExpiresAt ?? "-")
                ) : null}
                {details?.subscriptionActive ? (
                    <>
                        <View style={styles.fieldSet}>
                            <View style={[customStyles.section, customStyles.centeredContainer]}>
                                {renderCtaButton("Lõpeta pakett", handleUnsubscribeBtnClick)}
                            </View>
                        </View>
                        {tokenExpiry && tokenExpiry.paymentTokenExpiry && (
                            renderSection("Maksevahend kehtib kuni", tokenExpiry.paymentTokenExpiry)
                        )}
                        <View style={styles.fieldSet}>
                            <View style={[customStyles.section, customStyles.centeredContainer]}>
                                {renderCtaButton("Muuda kaardiandmeid", handlePaymentBtnClick)}
                                <Text style={styles.btnLabel}>Muutmine toimub 0€ maksega</Text>
                            </View>
                        </View>
                    </>
                ) : (
                    <>
                        {gracePeriodEndDate > now ? (
                            renderSection("Riiulis kuulatavad kuni", gracePeriodEndDate.toISOString().substring(0, 10) ?? "-")
                        ) : null}
                        <View style={styles.fieldSet}>
                            <View style={[customStyles.section, customStyles.centeredContainer]}>
                                {renderCtaButton(subscriptionButtonCtaText, handleSubscriptionBtnClick)}
                            </View>
                        </View>
                    </>
                )}
                <View style={styles.fieldSet}>
                    <View style={[customStyles.section, customStyles.centeredContainer]}>
                        {renderCtaButton("Aktiveeri kinkekaart", handleGiftCardButtonClick)}
                    </View>
                </View>
            </View>
        </View>
    );
};

const customStyles = StyleSheet.create({
    title: {
        fontSize: 18,
        lineHeight: 18,
        textAlignVertical: 'middle',
        textTransform: 'uppercase',
        fontWeight: "700"
    },
    section: {
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    text: {
        fontSize: 16,
        lineHeight: 18,
        textAlignVertical: 'middle'
    },
    btnContainer: {
        backgroundColor: "#6161FF",
        height: 40,
        borderRadius: 10,
        padding: 8,
        marginTop: 8,
        width: "80%",
        justifyContent: "center",
        alignItems: "center",
    },
    changeCardColor: {
        backgroundColor: "#6161FF",
    },
    unsubBtnColor: {
        backgroundColor: "#cb4a4a",
    },
    extraSpace: {
        marginTop: 20
    },
    centeredContainer: {
        justifyContent: "center",
        alignItems: "center",
    },
    btnText: {
        color: "white",
    },
});