export const getFullCategorySlug = (categories, id) => {
    if (categories[id]?.parentId) {
        return getFullCategorySlug(categories, categories[id].parentId) + '/' + categories[id].slug
    }
    return categories[id]?.slug;
}

export const getCategoryByFullSlug = (categories, slug) => {
    const slugs = slug.split('/')
    let parentId = null
    let category = null
    slugs.forEach(slug => {
        category = Object.values(categories).find(cat => cat.slug === slug && cat.parentId === parentId)
        parentId = category ? category.id : null
    })
    return category
}

export const getFullCategoryPath = (categories, id) => {
    let names = []
    function add(category) {
        names.unshift(category.name)
        if (category.parentId) {
            add(categories[category.parentId])
        }
    }
    add(categories[id])
    return names.join(': ')
}
