import React, {useEffect} from "react";
import {Helmet} from "react-native-web-ui-components/Helmet";
import Layout from "./Layout";
import css from "../styles";
import ScrollToTop from "./ScrollToTop";
import {showMessage} from "react-native-flash-message";

export default props => {
    useEffect(() => {
        if (process.env.API2_URL.toLowerCase().includes("staging")) {
            showMessage({
                message: 'Tegemist on staging keskkonnaga!',
                type: 'warning',
                autoHide: false,
                hideOnPress: true,
                icon: "warning",
            });
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <style>
                    {css}
                </style>
                <title>Digiread | audioraamatud</title>
                <meta name="description" content="Digiread on kirjastuste ühisettevõte, mis pakub parima valiku eestikeelseid audioraamatuid eesti näitlejate esituses! Tule ja kuula!" />
                <meta name="keyword" content="audioraamat, digiraamat, digiread, eestikeelne audioraamat, eestikeelne digikirjandus, heliraamat, eesti raamat, pegasus, hea lugu, eesti näitlejad, digikultuur, estonian audiobooks, digital culture, audiobook, helindajad, Jan Uuspõld, Taavi Eelmaa, Katrin Karisma, Kaur Kender, audioraamatu app, eestikeelsete audioraamatute app, eestikeelsed e-raamatud, eesti e-kirjandus, audiokirjandus, Mart Müürissepp, digibook, e-raamatud, uued audioraamatud, eesti klassika, skandinaavia audioraamatud, krimi, põnevus, ilukirjandus, eesti autorid, kuula kus iganes, Taavi Eelmaa soovitab, https://soundcloud.com/digiread, https://www.facebook.com/digiread/, https://www.youtube.com/channel/UCaOh9l7no5oBTjA5DJHyZQQ/videos, instagram_digiread_audioraamatud" />
            </Helmet>
            <ScrollToTop />
            <Layout {...props} />
        </React.Fragment>
    )
}
