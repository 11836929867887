import React, {useEffect} from "react";
import {showMessage} from "react-native-flash-message";
import {Mixpanel} from "../utils/mixpanel";
import {Image, Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";

const creditCardImage = {uri: require('../assets/images/credit_card.svg')}

const CardChangeCompleteScreen = () => {

    useEffect(() => {
        Mixpanel.track("Successful card change");
        showMessage({
            message: "Kaardiandmed uuendatud.",
            type: "success",
        });
    }, []);

    return (
        <React.Fragment>
            <View style={styles.container}>
                <View style={styles.infoBox}>
                    <Image
                        fixed
                        style={styles.optionImage}
                        source={creditCardImage}
                    />
                    <View style={styles.callToActionContainer}>
                        <Text style={styles.callToActionText}>
                            Täname, teie kaardiandmed on muudetud. Kui tahad jätkata äpiga, siis palun sulge
                            veebilehitseja.
                        </Text>
                    </View>
                </View>
            </View>
        </React.Fragment>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        justifyContent: 'center',
        alignItems: 'center',
    },
    callToActionContainer: {
        marginTop: '1em',
        paddingHorizontal: '1.5em',
        paddingBottom: '0.625em',
    },
    callToActionText: {
        fontSize: '1em'
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
});


export default CardChangeCompleteScreen;
