import React from "react";
import {Link, View} from "react-native-web-ui-components";
import {Dimensions, FlatList, Platform, StyleSheet} from "react-native";
import {Entypo} from "@expo/vector-icons";
import BookPreview from "./BookPreview";

class BookSlider extends React.Component {
    constructor(props) {
        super(props);
        this.offset = 0
    }

    _renderItem({item}) {
        return (
            <BookPreview
                style={styles.slide}
                id={item.id}
                key={item.id}
                title={item.title}
                authorFirstName={item.authorFirstName}
                authorLastName={item.authorLastName}
                image={item.image}
                slug={item.slug}
            />
        )
    }
    _getScreenWidth() {
        return Dimensions.get('window').width
    }
    _goLeft() {
        this.flatList.scrollToOffset({offset: this.offset - this._getScreenWidth() + 300})
    }
    _goRight() {
        this.flatList.scrollToOffset({offset: this.offset + this._getScreenWidth() - 300})
    }

    render() {
        return (
            <View style={styles.container}>
                <FlatList
                    ref={component => this.flatList = component}
                    contentContainerStyle={styles.content}
                    data={this.props.books}
                    renderItem={this._renderItem}
                    keyExtractor={(item, index) => `list-${item.id}-${index}`}
                    horizontal
                    snapToAlignment="start"
                    snapToInterval={200}
                    decelerationRate={"fast"}
                    pagingEnabled
                    showsHorizontalScrollIndicator={false}
                    onScroll={({nativeEvent}) => this.offset = nativeEvent.contentOffset.x}
                />
                {Platform.OS === 'web' ? (
                    <View style={styles.controls}>
                        <Link className="no-decoration" onPress={this._goLeft.bind(this)} style={[styles.controlContainer, styles.goLeftContainer]}>
                            <Entypo name="chevron-left" size={50} color="white" style={styles.controlContent}/>
                        </Link>
                        <Link className="no-decoration" onPress={this._goRight.bind(this)} style={[styles.controlContainer, styles.goRightContainer]}>
                            <Entypo name="chevron-right" size={50} color="white" style={styles.controlContent}/>
                        </Link>
                    </View>
                ) : null}
            </View>
        )
    }
}

export default BookSlider

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 10,
    },
    content: {
        marginRight: -10,
        marginLeft: -20,
    },
    slide: {
        marginLeft: 20,
    },
    controls: {
        position: 'absolute',
        width: '100%',
        height: 30,
        top: 125,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: -1,
    },
    controlContainer: {
        flex: 1,
        flexGrow: 0,
        zIndex: 1,
    },
    goLeftContainer: {
        marginLeft: -40,
    },
    goRightContainer: {
        marginRight: -40,
    },
    controlContent: {
        lineHeight: 30,
        textShadowColor: 'rgba(0, 0, 0, 1)',
        textShadowOffset: {width: 0, height: 0},
        textShadowRadius: 3,
    },
})
