import React from "react";
import {connect} from "react-redux";
import {StyleSheet} from "react-native";
import Link from "./Link";
import {getFullCategorySlug} from "../utils/category";

const CategoryLink = props => (
    <Link
        to={'/raamatud/kategooria/' + getFullCategorySlug(props.categories, props.category)}
        style={[styles.category, props.style]}
        textStyle={[styles.categoryText, props.textStyle]}
        hoverStyle={[styles.categoryHover, props.hoverStyle]}
        textHoverStyle={[styles.categoryTextHover, props.textHoverStyle]}
    >
        {props.categories[props.category].name}
    </Link>
)

const mapStateToProps = ({categories}) => ({categories})

export default connect(mapStateToProps)(CategoryLink)

const styles = StyleSheet.create({
    category: {
        paddingRight: 10,
    },
    categoryText: {
        fontSize: 24,
        lineHeight: 30,
        fontFamily: 'Aino-Regular',
        color: 'rgb(37, 37, 37)',
        textDecorationLine: 'underline',
    },
    categoryHover: {

    },
    categoryTextHover: {
        color: 'rgb(84, 84, 84)',
        textDecorationLine: 'none',
    },
})
