import * as React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    // TODO Log error
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>Midagi läks valesti, palun proovige uuesti või pöörduge abi saamiseks <b>info@digiread.ee</b></p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;