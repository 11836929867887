import React, {useEffect} from "react";
import {Platform} from "react-native";
import {connect} from "react-redux";
import axios from "axios";
import LandingScreen from "./LandingScreen";
import {useHistory} from "../routing";
import {SET_USER_DATA} from "../redux/actions";
import LibraryScreen from "./LibraryScreen";
import TabViewScreen from "./TabViewScreen";

const HomeScreen = ({user, dispatch}) => {
    const history = useHistory();
    if (!user && Platform.OS !== 'web') {
        history.replace('/login');
        return null;
    }
    useEffect(() => {
        if (!user || (user.emailVerifiedAt && user.subscriptionExpiresAt)) return
        const field = user.emailVerifiedAt ? 'subscriptionExpiresAt' : 'emailVerifiedAt'
        const handle = setInterval(() => {
            axios.get(process.env.API2_URL + '/user').then(res => {
                if (res.data[field]) {
                    dispatch({
                        type: SET_USER_DATA,
                        payload: res.data,
                    })
                }
            })
        }, 2000)
        return () => clearInterval(handle)
    }, [user])
    if (!user) {
        return (
            <LandingScreen/>
        )
    }

    return Platform.OS === 'web' ? (
        <LibraryScreen/>
    ) : (
        <TabViewScreen/>
    )
}

const mapStateToProps = ({user}) => ({user})

export default connect(mapStateToProps, null)(HomeScreen)
