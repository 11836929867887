import React, {useEffect} from "react"
import {timing, Easing, concat} from 'react-native-reanimated'
import {Text, View} from 'react-native-web-ui-components'
import {Platform, StyleSheet} from 'react-native'
import IconButton from '../IconButton'
import Slider from '../Slider'
import useStyles from '../../hooks/useStyles'
import {AnimatedView, useValue} from '../animated'

const VolumeControl = props => {
    const {
        styles,
        volume,
        onChange,
        sliderSize
    } = props

    return Platform.OS === 'web' ? (
        <View style={styles.settingContainer}>
            <View style={styles.settingTitleContainer}>
                <Text style={styles.settingTitleText}>Heli tugevus</Text><Text style={styles.settingSliderValue}>{Math.trunc(volume*10)}</Text>
            </View>
            <View style={styles.settingSliderContainer}>
                <Slider sliderSize={sliderSize} allowOverlap={true} values={[volume]} min={0} max={1.0} step={0.1} snapped={true} onValuesChange={onChange} />
            </View>
        </View>
    ) : null
}

const SpeedControl = props => {
    const {
        styles,
        speed,
        onChange,
        sliderSize
    } = props

    return (
        <View style={styles.settingContainer}>
            <View style={styles.settingTitleContainer}>
                <Text style={styles.settingTitleText}>Lugemise kiirus</Text><Text style={styles.settingSliderValue}>{speed.toFixed(1)}</Text>
            </View>
            <View style={styles.settingSliderContainer}>
                <Slider sliderSize={sliderSize} allowOverlap={true} values={[speed]} min={0.5} max={2.5} snapped={true} step={0.1} onValuesChange={onChange} />
            </View>
        </View>
    )
}

export default function Settings(props) {
    const {
        visible = true,
        volume,
        rate,
        onChangeVolume,
        onChangeSpeed,
        sliderSize,
        ...rest
    } = props
    const percentage = useValue(0)
    const styles = useStyles(createStyles)

    useEffect(() => {
        const animation = timing(percentage, {
            duration: 0,
            toValue: visible ? 1 : 0,
            easing: Easing.inOut(Easing.ease),
        })
        //animation.start()
        percentage.setValue(visible ? 1 : 0)
    }, [visible])

    return (
        <View pointerEvents="box-none" {...rest}>
            <AnimatedView style={{height: concat(percentage.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 100],
                }), '%'), overflowX: 'visible', overflowY: 'hidden', opacity: percentage, backgroundColor: 'white'}}>
                <View style={styles.title}>
                    <IconButton name="settings" style={styles.icon} size={50} />
                    <Text style={styles.titleText}>Sätted</Text>
                </View>
                <VolumeControl
                    styles={styles}
                    volume={volume}
                    onChange={onChangeVolume}
                    sliderSize={sliderSize}
                />
                <SpeedControl
                    styles={styles}
                    speed={rate}
                    onChange={onChangeSpeed}
                    sliderSize={sliderSize}
                />
            </AnimatedView>
        </View>
    )
}

const createStyles = ({width}) => StyleSheet.create({
    title: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginRight: 10,
        marginBottom: 10,
    },
    titleText: {
        fontSize: 20,
        lineHeight: 24,
        fontFamily: 'Aino-Headline',
    },
    settingContainer: {
        padding: 5,
        paddingRight: 20,
        paddingLeft: 20
    },
    settingTitleContainer: {
        flexDirection: 'row',
    },
    settingTitleText: {
        fontSize: 20,
        lineHeight: 24,
        fontFamily: 'Aino-Headline',
    },
    settingSliderContainer: {

    },
    settingSliderValue: {
        width: 'auto',
        fontSize: 20,
        lineHeight: 24,
        fontFamily: 'Aino-Regular',
    }
})
