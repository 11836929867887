import React, {useCallback, useEffect, useState} from "react";
import {Image, Loading, Row, Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import {connect, useDispatch, useSelector} from "react-redux";
import {useDimensions} from "react-native-responsive-ui/src";
import {showMessage} from "react-native-flash-message";
import {useHistory, useParams} from "../routing";
import Button from "../components/Button";
import {addBookToLibrary, PLAYER_OPEN} from "../redux/actions";
import CategoryLink from "../components/CategoryLink";
import {useLibrary} from "../modules/Library";

import Success from "../assets/icons/success.svg";
import Play from "../assets/icons/play.svg";
import Euro from "../assets/icons/euro.svg";
import {addBookToCart} from "../redux/cart/cartActions";
import {selectUserSubscriptionDiscountActive} from "../redux/reducers";
import {useAlert} from "../components/Alert";
import {useSubscriberDiscountCalculator} from "../hooks/useSubscriberDiscountCalculator";
import {Helmet} from "react-native-web-ui-components/Helmet/index.web";
import {Mixpanel} from "../utils/mixpanel";
import MiniAudioPlayer from "../components/AudioPlayer/MiniAudioPlayer";
import axios from "axios";
import {selectSubscriptionMonthlyTokensCount} from "../redux/config/configSelectors";

const BookDetailsScreen = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {slug} = useParams();
    const books = useSelector(state => state.products);
    const user = useSelector(state => state.user);
    const [book, setBook] = useState();
    const {library, isLoading, mutateLibrary} = useLibrary();
    const history = useHistory();
    const [inUserLibrary, setInUserLibrary] = useState(null);
    const [updateButtons, setUpdateButtons] = useState(true);

    const addToLibraryAlert = useAlert({
        title: "Lisa raamat",
        message: `Kas oled kindel, et soovid raamatut "${book?.author} - ${book?.title}" oma raamaturiiulisse lisada?`,
        cancelText: "Ei",
        confirmText: "Jah",
        onConfirmPressed: () => {
            dispatch(addBookToLibrary(book, user)).then(() => {
                mutateLibrary([...library, book], true).then(() => {
                    setInUserLibrary(true);
                    setUpdateButtons(false);
                    showMessage({
                        message: "Raamat edukalt lisatud! Kuulamiseks vajuta nuppu \"kuula\".",
                        type: "success",
                    });
                    Mixpanel.track("Book added to library", {"bookId": book.id, "author": book.author, "title": book.title});
                });
            });
        },
    });

    const addToCartAlert = useAlert({
        title: "Raamat ostukorvi lisatud",
        message: `Raamat "${book?.author} - ${book?.title}" on ostukorvi lisatud.`,
        cancelText: "Sulge",
        confirmText: "Vaata ostukorvi",
        onConfirmPressed: () => {
            history.push("/ostukorv");
        },
    });

    useEffect(() => {
        if (updateButtons && book) {
            const inLibrary = user && library && library.filter(item => item.id === book.id).length > 0;
            setInUserLibrary(inLibrary);
        }

        if (!isLoading && book === undefined) {
            const libraryBook = library.find(b => b.slug === slug);
            const availableBook = books.find(b => b.slug === slug);
            if (!availableBook && libraryBook) {
                // Handle a case when the users library contains a book that is no longer being sold
                setBook(libraryBook);
            } else {
                setBook(availableBook);
            }
        }

    }, [user, library, isLoading, book]);

    useEffect(() => {
        dispatch({
            type: 'SET_SEARCH_QUERY_BOOLEAN',
            payload: true,
        });
    }, []);

    if (isLoading || book === undefined) {
        return <Loading/>;
    }

    const source = {uri: book.image};

    return <>
        <Helmet>
            <meta name="description" content={`${book.authorFirstName} ${book.authorLastName}  - ${book.title}`} />
            <title>{`${book.title} - ${book.authorFirstName} ${book.authorLastName} | Digiread`}</title>
        </Helmet>
        <View style={styles.container}>
          <View style={styles.headerContainer}/>
          <View style={styles.detailsContainer}>
              <View>
                  <Row>
                      <Image fixed style={styles.image} source={source} alt={book.author + " - " + book.title}/>
                  </Row>
              </View>
              <View style={styles.detailsWrapper}>
                  <DetailsTable styles={styles} book={book}/>
                  <View style={styles.buttonsContainer}>

                  </View>
              </View>
          </View>
          <View style={styles.buttonsContainer}>
              <ButtonRow book={book} user={user} inUserLibrary={inUserLibrary} libraryAlert={addToLibraryAlert}
                         cartAlert={addToCartAlert}/>
          </View>

          <View style={styles.descriptionContainer}>
              <Description styles={styles} description={book.description}/>
          </View>
        </View>
    </>

}

const Description = props => {
    const [full, setFull] = useState(false)

    return <React.Fragment>
        <Button type="dark" onPress={() => {
            setFull(!full)
        }}>{full ? "Sulge" : "Loe lähemalt"}</Button>
        <Text numberOfLines={full ? undefined : 2} style={props.styles.descriptionText}>{props.description}</Text>
    </React.Fragment>
}

const getDurationString = durationSeconds => {
    if (!durationSeconds) {
        return "-";
    }
    let h = Math.floor(durationSeconds / 3600);
    const m = Math.floor(durationSeconds % 3600 / 60);
    const hourString = h > 1 ? `${h} tundi` : h === 0 ? "" : "1 tund";
    let minuteString = "";
    if (m > 0) {
        minuteString = m > 1 ? ` ${m} minutit` : " 1 minut";
    }
    return hourString + minuteString;
}

class DetailsTableBase extends React.Component {
    _renderRow(name, value) {
        return value ? (
            <View style={this.props.styles.tableRow} key={name}>
                <View style={[this.props.styles.tableColumn, this.props.styles.tableHeaderColumn]}>
                    <Text style={this.props.styles.tableHeaderText}>{name}</Text>
                </View>
                <View style={[this.props.styles.tableColumn, this.props.styles.tableCellColumn]}>
                    {typeof value === 'function' ? value : <Text style={this.props.styles.tableCellText}>{value}</Text>}
                </View>
            </View>
        ) : null
    }

    render() {
        const attributes = {
            "Pealkiri": this.props.book.title,
            "Autor": this.props.book.author,
            "Lugeja": this.props.book.reader,
            "Kirjastus": this.props.book.publisher,
            "Kategooriad": renderCategories(this.props.book.categoryIds, this.props.styles),
            "Aasta": this.props.book.publishYear,
            "Kestus": getDurationString(this.props.book.length),
        }
        return (
            <View style={this.props.styles.table}>
                {
                    Object.entries(attributes).map((attribute) => this._renderRow(attribute[0], attribute[1]))
                }
            </View>
        )
    }
}

const ButtonRow = ({book, user, inUserLibrary, libraryAlert, cartAlert}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const isDiscountActive = useSelector(selectUserSubscriptionDiscountActive);
    const subscriptionMonthlyTokensCount = useSelector(selectSubscriptionMonthlyTokensCount);
    const history = useHistory();
    const isAvailableForSubscription = book.paymentType === "ALL" || book.paymentType === "SUBSCRIPTION";
    const isAvailableForSinglePurchase = book.paymentType === "ALL" || book.paymentType === "PURCHASE";
    const canAddToLibrary = !!(!inUserLibrary && user && user.subscriptionTokens) && isAvailableForSubscription;
    const calculateDiscountPrice = useSubscriberDiscountCalculator();
    const [sampleFile, setSampleFile] = useState();
    const getSampleFile = useCallback(async () => {
        if (!book) {
            return;
        }
        try {
            const res = await axios.get(`${process.env.API2_URL}/books/${book.id}/sample`);
            if (res.data.file) {
                setSampleFile(res.data.file);
            }
        } catch (e) {
            //ignore
        }

    }, [book]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getSampleFile();
    }, [getSampleFile]);

    const isEligibleForStartingSubscription = !isDiscountActive && isAvailableForSubscription;
    const isFree = book.price === 0;
    const canGetFree = (isAvailableForSubscription && isDiscountActive && isFree)
        || (isAvailableForSinglePurchase && isFree);

    const handleSubscriptionPress = () => {
        if (!user) {
            history.push("/registreerumine");
        } else {
            if (user.subscriptionExpiresAt) {
                history.push("/paketiost");
            } else {
                history.push("/kaardimakse/start-trial/web");
            }

        }
    };

    const handleAddToCartPress = () => {
        dispatch(addBookToCart(book.id));
        if (!user) {
            history.push("/registreerumine");
        } else {
            Mixpanel.track("Book added to cart", {"bookId": book.id, "author": book.author, "title": book.title});
            cartAlert.show();
        }
    }

    const subscriptionExpiresAt = user?.subscriptionExpiresAt;
    const subscriptionCta = subscriptionExpiresAt ? "Liitu paketiga" : "Alusta prooviperioodi";
    const subscriptionDescription = subscriptionExpiresAt
      ? `${subscriptionMonthlyTokensCount} ${subscriptionMonthlyTokensCount === 1 ? "raamat" : "raamatut"} 6.99€ kuus`
      : "2 nädalat tasuta";

    return (
        <Row style={styles.buttonRow}>
            {inUserLibrary &&
                <Button style={styles.button} onPress={() => dispatch({
                    type: PLAYER_OPEN,
                    bookId: book.id,
                })}>
                    <Play width={60}/>
                    <Text style={styles.buttonText}>Kuula</Text>
                </Button>
            }
            {!inUserLibrary &&
            <>
                {sampleFile &&
                <View style={styles.button}>
                    <MiniAudioPlayer bookId={book.id} sampleFile={sampleFile}/>
                </View>
                }
                {isEligibleForStartingSubscription && !isFree &&
                <Button style={styles.button} onPress={handleSubscriptionPress}>
                    <Success width={60}/>
                    <Text style={styles.buttonText}>{subscriptionCta}</Text>
                    <Text style={styles.buttonDescription}>{subscriptionDescription}</Text>
                </Button>
                }
                {isEligibleForStartingSubscription && isFree &&
                <Button style={styles.button} onPress={handleSubscriptionPress}>
                    <Success width={60}/>
                    <Text style={styles.buttonText}>{subscriptionCta}</Text>
                    <Text style={styles.buttonDescription}>{!!user ? "Liitu paketiga ning lisa tasuta riiulisse" : "Logi sisse paketikasutajana ning lisa tasuta riiulisse"}</Text>
                </Button>
                }
                {canAddToLibrary && !isFree &&
                <Button style={styles.button} onPress={libraryAlert.show}>
                    <Success width={60}/>
                    <Text style={styles.buttonText}>Lisa riiulisse</Text>
                </Button>
                }
                {isAvailableForSinglePurchase && !isFree &&
                <Button style={styles.button} onPress={handleAddToCartPress}>
                    <Euro width={60}/>
                    <Text style={styles.buttonText}>Lisa ostukorvi</Text>
                    {isDiscountActive &&
                        <>
                            <Text style={[styles.buttonDescription, styles.strikethroughPrice]}>{" " + book.price + "€ "}</Text>
                            <Text style={styles.buttonDescription}>{calculateDiscountPrice(book.price) + "€"}</Text>
                        </>
                    }
                    {!isDiscountActive &&
                        <Text style={styles.buttonDescription}>{book.price + "€"}</Text>
                    }
                </Button>
                }
                {canGetFree &&
                <Button style={styles.button} onPress={libraryAlert.show}>
                    <Success width={60}/>
                    <Text style={styles.buttonText}>Lisa tasuta riiulisse</Text>
                </Button>
                }
            </>
            }
        </Row>
    );
}

const DetailsTable = connect(({categories}) => ({categories}))(DetailsTableBase)

const renderCategories = (categories, styles) => {
    if (!categories || categories.length === 0) {
        return "-";
    }

    return categories.map(category => {
        return <CategoryLink textStyle={styles.tableCellText} key={category} category={category}/>
    });
};

export default BookDetailsScreen

const useStyles = () => {
    const {width} = useDimensions()
    const isWide = width > 660

    return StyleSheet.create({
        container: {
            flex: 1,
        },
        headerContainer: {},
        detailsContainer: {
            paddingHorizontal: width > 900 ? 50 : 25,
            flexDirection: isWide ? 'row' : 'column',
            paddingTop: 20,
        },
        image: {
            width: isWide ? 183 : '100%',
            height: 'auto',
            marginBottom: isWide ? 0 : 10,
        },
        detailsWrapper: {
            flex: 1,
            paddingLeft: 20,
        },
        table: {
            alignItems: 'stretch',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        tableRow: {
            width: '100%',
            flexDirection: 'row',
        },
        tableColumn: {
            flexGrow: 1,
            alignSelf: 'stretch',
            overflow: 'visible',
        },
        tableHeaderColumn: {
            width: isWide ? 165 : 133,
            flexGrow: 0,
        },
        tableCellColumn: {
            flex: 1,
            paddingBottom: 6,
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        tableHeaderText: {
            fontSize: isWide ? 24 : 20,
            lineHeight: isWide ? 30 : 25,
            fontFamily: 'Aino-Headline',
            color: 'rgb(37, 37, 37)',
        },
        tableCellText: {
            fontSize: isWide ? 24 : 20,
            lineHeight: isWide ? 30 : 25,
            fontFamily: 'Aino-Regular',
            color: 'rgb(37, 37, 37)',
        },
        buttonsContainer: {
            paddingHorizontal: width > 900 ? 50 : 25,
            flexDirection: 'row',
        },
        descriptionContainer: {
            paddingHorizontal: width > 900 ? 50 : 25,
            alignItems: 'center',
            borderTopWidth: 2,
            paddingTop: 20,
            marginTop: 20,
        },
        descriptionText: {
            fontSize: isWide ? 24 : 20,
            lineHeight: isWide ? 30 : 25,
            fontFamily: 'Aino-Headline',
            color: 'rgb(37, 37, 37)',
            paddingTop: 20,
            textAlign: 'left',
        },
        buttonRow: {
            justifyContent: isWide ? 'flex-start' : 'center'
        },
        button: {
            width: isWide ? '165px' : '100%',
            alignItems: 'center',
        },
        buttonText: {
            textTransform: 'uppercase',
            textDecorationLine: 'none',
            textAlign: "center",
            fontSize: 14,
            lineHeight: 18,
            marginTop: 10,
            fontFamily: "Aino-Bold",
        },
        buttonDescription: {
            fontFamily: "Aino-Bold",
            fontSize: 14,
            textAlign: "center",
        },
        strikethroughPrice: {
            textDecorationLine: "line-through",
            color: "#6d6d6d",
        },
    })
}
