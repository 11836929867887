import {useState, useEffect} from "react"
import {Platform} from "react-native"
import packageJson from '../package.json'
global.appVersion = packageJson.version

export default function useCacheBuster() {
    if (Platform.OS !== 'web') {
        return {
            loading: false,
            isLatestVersion: true,
            refreshCacheAndReload: () => null,
        }
    }
    const [loading, setLoading] = useState(true)
    const [isLatestVersion, setIsLatestVersion] = useState(false)
    const refreshCacheAndReload = async () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            const names = await caches.keys()
            for (let name of names) {
                await caches.delete(name)
            }
        }
        // delete browser cache and hard reload
        window.location.reload(true)
    }
    useEffect(() => {
        fetch('/meta.json?' + new Date().getTime(), {
            cache: 'no-cache'
        })
            .then(response => response.json())
            .then(meta => {
                const semver = require('semver')
                const latestVersion = meta.version
                const currentVersion = global.appVersion

                const shouldForceRefresh = semver.gt(latestVersion, currentVersion)
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`)
                    setIsLatestVersion(false)
                } else {
                    console.log(`We already have the latest version - ${latestVersion}. No cache refresh needed.`)
                    setIsLatestVersion(true)
                }
                setLoading(false)
            })
            .catch(e => {
                console.log('meta.json not found, possibly due to development environment.')
                setLoading(false)
                setIsLatestVersion(true)
            })
    }, [])
    return {
        loading,
        isLatestVersion,
        refreshCacheAndReload,
    }
}
