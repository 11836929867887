export const ADD_BOOK_TO_CART = "ADD_BOOK_TO_CART";
export const REMOVE_BOOKS_FROM_CART = "REMOVE_BOOKS_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";

export const addBookToCart = bookId => ({
    type: ADD_BOOK_TO_CART,
    bookId,
});


export const removeBooksFromCart = bookIds => ({
    type: REMOVE_BOOKS_FROM_CART,
    bookIds,
});

export const emptyCart = () => ({
    type: EMPTY_CART,
});
