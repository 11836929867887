import React from 'react'
import {Text, View} from "react-native";
import {Link, TouchableOpacity} from "react-native-web-ui-components";

class HoverableView extends React.Component {
    setStyles = (style, textStyle) => {
        this.root.setNativeProps({style})
        this.text && this.text.setNativeProps({
            style: textStyle,
        })
    }

    render() {
        const {
            hoverStyle,
            style,
            textStyle,
            textHoverStyle,
            linkStyle,
            linkHoverStyle,
            children,
            to,
            onPress,
            disabled,
            onMouseEnter,
            onMouseLeave,
            ...rest
        } = this.props

        const Inner = props => (
            <TouchableOpacity
                onRef={component => {this.root = component}}
                onMouseEnter={() => {
                    this.setStyles(hoverStyle, textHoverStyle)
                    onMouseEnter && onMouseEnter()
                }}
                onMouseLeave={() => {
                    this.setStyles(style, textStyle)
                    onMouseLeave && onMouseLeave()
                }}
                style={style}
                onPress={onPress}
                disabled={disabled}
            >
                {typeof children !== 'string' ? children :
                    <Text
                        ref={component => {
                            this.text = component
                        }}
                        style={textStyle}
                    >{children}</Text>
                }
            </TouchableOpacity>
        )

        return to ? (
            <Link
                to={to}
                className="no-decoration"
                style={{width: 'auto'}}
                {...rest}
            >
                <Inner />
            </Link>
        ) : (
            <Inner />
        )
    }
}

export default HoverableView
