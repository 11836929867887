import React from "react";
import {Image, Text, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import Link from "./Link";
import {useHistory} from "../routing";
import {Mixpanel} from "../utils/mixpanel";
import {useDimensions} from "react-native-responsive-ui/src/index";

export default function BookPreview(props) {
    const {
        title,
        authorFirstName,
        authorLastName,
        image,
        slug,
        style,
        id,
        ...rest
    } = props

    const author = `${authorFirstName} ${authorLastName}`

    const {width} = useDimensions();
    const isWide = width > 900;
    const source = {uri: image}
    const detailsUrl = '/raamatud/' + slug
    const history = useHistory();

    const handleBookPreviewClick = () => {
        Mixpanel.track("Book details click", {"bookId": id, "author": author, "title": title});
        history.push(detailsUrl);
    };

    return (
        <View style={style ? [styles(isWide).container, style] : styles(isWide).container} {...rest}>
            <Link draggable="false" onPress={handleBookPreviewClick}>
                <View style={styles(isWide).innerContainer}>
                    <Image fixed style={imageStyle(isWide)} source={source} alt={author + ' - ' + title}/>
                    <View style={styles(isWide).titleContainer}><Text style={styles(isWide).titleText}>{title}</Text></View>
                    <View style={styles(isWide).authorContainer}><Text style={styles(isWide).authorText}>{author}</Text></View>
                </View>
            </Link>
        </View>
    )
}

const styles = isWide => StyleSheet.create({
    container: {
        width: isWide ? 180 : 90,
    },
    innerContainer: {

    },
    titleContainer: {

    },
    titleText: {
        textAlign: 'center',
        //textTransform: 'uppercase',
        fontFamily: 'Aino-Bold',
        fontSize: isWide ? 16 : 12,
        fontWeight: '700',
        paddingTop: 10,
        paddingBottom: 5,
    },
    authorContainer: {

    },
    authorText: {
        textAlign: 'center',
        fontFamily: 'Aino-Regular',
        fontSize: isWide ? 14 : 10,
    }
})

const imageStyle = isWide => ({
    width: isWide ? 180 : 90,
    height: isWide ? 260: 130,
    pointerEvents: 'none',
});
