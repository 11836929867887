import React from 'react';
import {StyleSheet, ScrollView} from "react-native";
import {UIProvider, View} from "react-native-web-ui-components";
import {convertUITheme} from "../themes";
import Header from "./Header";
import Screens from "./Screens";
import {TOP_MENU_HEIGHT} from "../constants";
import AudioPlayerModal from "./AudioPlayerModal";
import {useSelector} from "react-redux";
import Footer from './Footer'
import ErrorBoundary from "./error/ErrorBoundary";

export default () => {
    const blur = useSelector(state => state.isMenuOpen)
    const blurStyle = blur ? {
        filter: 'blur(2px)',
        transition: 'filter 0.5s ease',
    } : {}

    return (
        <UIProvider theme={convertUITheme()} amp={false}>
            <View style={styles.container}>
                <ErrorBoundary>
                    <Header/>
                </ErrorBoundary>
                <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContent}>
                    <View style={styles.screenView}>
                        <Screens style={[styles.scrollViewContent, blurStyle]}/>
                    </View>
                    <ErrorBoundary>
                        <Footer />
                    </ErrorBoundary>
                </ScrollView>
                <AudioPlayerModal/>
            </View>
        </UIProvider>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
    scrollView: {
        paddingTop: TOP_MENU_HEIGHT,
    },
    scrollViewContent: {
        minHeight: '100%',
    },
    screenView: {
        display: "flex",
        flex: 1,
    },
});
