import React, {useEffect, useState} from "react";
import {Link, TextInput, View} from "react-native-web-ui-components";
import {useForm} from "react-hook-form";
import {showMessage} from "react-native-flash-message";
import {useDispatch} from "react-redux";
import {FontAwesome} from "@expo/vector-icons";
import {useDimensions} from "react-native-responsive-ui/src";
import axios from "axios";
import {ValidationError} from "../components/forms";
import {SET_USER_DATA} from "../redux/actions";
import {useHistory, useLocation} from "../routing";
import Button from "../components/Button";
import {useFormStyles} from "../components/forms/styles";
import {Mixpanel} from "../utils/mixpanel";
import {StyleSheet} from "react-native";

const LoginScreen = props => {
    const {register, setValue, handleSubmit, errors} = useForm();
    const [passwordInput, setPasswordInput] = useState(null)
    const dispatch = useDispatch()
    const history = useHistory()
    const styles = useFormStyles()
    const {width} = useDimensions()
    const location = useLocation();

    const submit = data => {
        axios.post(process.env.API2_URL + '/login', {
            email: data.email,
            password: data.password,
        }).then(({data}) => {
            dispatch({
                type: SET_USER_DATA,
                payload: data,
            });
            Mixpanel.track("Login");
            Mixpanel.people.set({
                $first_name: data.firstName,
                $last_name: data.lastName,
                $email: data.email
            });

            const redirect = location.state?.redirect;
            if (redirect) {
                history.push(redirect);
            } else {
                history.replace('/');
            }
        }).catch(error => {
            showMessage({
                message: 'Sisselogimine ebaõnnestus!',
                type: 'danger',
            })
        })
    }

    useEffect(() => {
        register({name: 'email'}, {required: true});
        register({name: 'password'}, {required: true});
    }, [register])

    const customStyles = StyleSheet.create({
        forgotPasswordLink: {
            alignSelf: 'center',
            width: 'auto',
            marginHorizontal: 20,
            marginTop: width > 500 ? 0 : 10,
            fontSize: 16
        },
        footer: {
            justifyContent: 'space-between',
            flexDirection: width > 500 ? 'row' : 'column'
        }
    });

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View style={styles.fieldSet}>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                autoFocus
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Meiliaadress"
                                keyboardType="email-address"
                                //autoCompleteType="email"
                                onChangeText={email => setValue('email', email, true)}
                                returnKeyType="next"
                                onSubmitEditing={() => {
                                    passwordInput.focus()
                                }}
                                blurOnSubmit={false}
                            />
                            {errors.email?.type === 'required' &&
                            <ValidationError>See väli on kohustuslik.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="envelope" style={styles.icon}/>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.input}
                                focusStyle={[styles.input, styles.inputFocus]}
                                placeholder="Parool"
                                onRef={setPasswordInput}
                                secureTextEntry
                                //autoCompleteType="password"
                                onChangeText={password => setValue('password', password, true)}
                                onSubmitEditing={handleSubmit(submit)}
                            />
                            {errors.password?.type === 'required' &&
                            <ValidationError>See väli on kohustuslik.</ValidationError>}
                        </View>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="lock" style={styles.icon}/>
                        </View>
                    </View>
                </View>

                <View style={[styles.footer, customStyles.footer]}>
                    <Button
                        auto={width > 500}
                        type="dark"
                        style={styles.button}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={handleSubmit(submit)}
                    >
                        Logi sisse
                    </Button>
                    <Link
                        style={customStyles.forgotPasswordLink}
                        to="/unustasid-parooli">
                        Unustasid salasõna?
                    </Link>
                </View>
            </View>
        </View>
    );
}

export default LoginScreen
