import React, {useEffect, useState, useCallback} from "react";
import {TextInput, View} from "react-native-web-ui-components";
import {ImageBackground, StyleSheet} from "react-native";
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "../routing";
import Button from "../components/Button";
import {getCategoryByFullSlug, getFullCategorySlug} from "../utils/category";
import BooksList from "../components/BooksList";
import ScreenTitle from "../components/ScreenTitle";
import {useUser} from "../modules/User";
import {useFormStyles} from "../components/forms/styles";
import axios from "axios";
import {debounce} from "lodash";
import {SET_SEARCH_QUERY} from "../redux/actions";

const backgroundImage = { uri: require('../assets/images/bg_kategooriad.jpg') }

const BooksScreen = props => {
    const [query, setQuery] = useState("");
    const [products, setProducts] = useState(props.products.filter(book => !book.hasOwnProperty('isVisible') || book.isVisible));
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {isLoggedIn} = useUser();
    const formStyles = useFormStyles();
    const dispatch = useDispatch();
    const searchQuery = useSelector(state => state.searchQuery.query);
    const lastPath = useSelector(state => state.lastPath);
    const isFromDetails = lastPath?.at(-1)?.startsWith("/raamatud/");
    let category = null

    const mapCategoryBooks = () => {
        if (props.match.params.slug) {
            category = getCategoryByFullSlug(props.allCategories, props.match.params.slug);
            if (category.bookIds) {
                setFilteredProducts(category.bookIds
                    .map(bookId => products.find(product => product?.id === bookId))
                    .filter(product => product !== undefined));
            } else {
                setFilteredProducts(products.filter(book => book.categoryIds.includes(category.id)));
            }
        } else {
            setFilteredProducts(products);
        }
    }

    useEffect(() => {
        mapCategoryBooks();
    }, [props.match.params.slug, products]);

    const searchBooks = async (searchQuery) => {
        return axios.get(`${process.env.API2_URL}/books/search?q=${searchQuery}`);
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        isFromDetails ? setQuery(searchQuery) : dispatch({type: SET_SEARCH_QUERY, payload: ""});
    }, []);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        searchBooksByQuery(query);
    }, [query]);

    const searchBooksByQuery = useCallback(async (searchTerm) => {
        dispatch({type: SET_SEARCH_QUERY, payload: searchTerm});
        if (!searchTerm) {
            setProducts(props.products.filter(book => !book.hasOwnProperty('isVisible') || book.isVisible));
            return;
        }
        if (searchTerm.length < 3) {
            return;
        }
        setIsLoading(true);
        const response = await searchBooks(searchTerm);
        setProducts(response.data);
        setIsLoading(false);
    }, []);

    const updateSearchQuery = (search) => {
        if (search !== undefined) {
            setQuery(search);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.titleRow}>
                <View style={styles.listContainer}>
                    <ScreenTitle style={styles.title}>{category ? category.name : 'Kõik raamatud'}</ScreenTitle>
                    {isLoggedIn ? (
                        <Button type="dark" style={styles.listTitleButton} to="/">Minu raamatud</Button>
                    ) : null}
                </View>
                <TextInput
                    autoFocus
                    style={styles.searchBox}
                    focusStyle={formStyles.inputFocus}
                    placeholder="Otsi raamatut"
                    onChangeText={debounce(updateSearchQuery, 300)}
                    returnKeyType="next"
                    blurOnSubmit={false}
                    defaultValue={isFromDetails && searchQuery?.length > 1 ? searchQuery : null}
                    onSubmitEditing={() => {}}
                />
            </View>
            {!isLoading && <BooksList books={filteredProducts}/>}
            <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
                <View style={styles.categories}>
                    {Object.keys(props.categories).map(index => (
                        <Button
                            key={index + props.categories[index].slug}
                            style={styles.category}
                            textStyle={styles.categoryText}
                            hoverStyle={styles.categoryHover}
                            textHoverStyle={styles.categoryTextHover}
                            to={'/raamatud/kategooria/' + getFullCategorySlug(props.allCategories, index)}
                        >{props.categories[index].name}</Button>
                    ))}
                </View>
            </ImageBackground>
        </View>
    );
}

const mapStateToProps = state => {
    const allCategories = state ? state.categories : {}
    const categories = Object.fromEntries(
        Object.entries(allCategories)
            .filter(([id, item]) => item.includeInMenu && !item.parentId)
    )
    return {
        products: state ? state.products : [],
        categories,
        allCategories,
    }
}

export default connect(mapStateToProps, null)(withRouter(BooksScreen));

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    titleRow: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: 50,
        marginBottom: 25,
    },
    listContainer: {
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8
    },
    title: {
        marginRight: 20,
        marginTop: 30
    },
    listTitleButton: {
        marginRight: 20,
    },
    backgroundImage: {
        flex: 1,
        justifyContent: 'center',
    },
    categories: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: 50,
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    category: {
        marginHorizontal: 50,
        marginVertical: 10,
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 20,
    },
    categoryText: {
        fontFamily: 'Aino-Bold',
    },
    categoryHover: {

    },
    categoryTextHover: {

    },
    searchBox: {
        display: 'flex',
        borderColor: 'rgb(102, 102, 102)',
        color: 'rgb(102, 102, 102)',
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4,
        backgroundColor: '#fff',
        fontSize: 16,
        fontFamily: 'Aino-Regular',
        width: '100%',
        minWidth: 150,
        maxWidth: 300,
        height: 34
    },
})
