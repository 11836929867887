import { StyleSheet } from 'react-native';

export const lightTheme = {
    body: '#fff',
    text: '#000',
    navBar: {
        color: '#fff',
        item: '#252525',
        activeItem: 'yellow',
    },
    footer: {
        color: '#ccc',
        text: '#000',
    },
    registerButton: {
        text: '#fff',
        background: 'transparent',
        border: '#fff',
    }
}

export const darkTheme = {
    body: '#000',
    text: '#fff',
    navBar: {
        color: '#0072CE',
        item: '#fff',
    },
    footer: {
        color: '#fff',
        text: '#000',
    }
}

export function convertUITheme(themeName = 'light') {
    const theme = themeName === 'light' ? lightTheme : darkTheme;
    return {
        '*': {
            fontFamily: {
                regular: 'MyriadPro-Regular',
                bold: 'MyriadPro-Regular',
            }
        },
        colors: {
            text: 'textColor',
            textColor: StyleSheet.create({
                text: { color: theme.text },
            }),
            menuLink: StyleSheet.create({
                text: {color: theme.navBar.item},
            }),
            menuLinkActive: StyleSheet.create({
                text: {color: theme.navBar.activeItem},
            }),
            registerButton: StyleSheet.create({
                text: {color: theme.registerButton.text},
                background: {backgroundColor: theme.registerButton.background},
                border: {
                    borderColor: theme.registerButton.border,
                },
            }),
        },
        input: {
            regular: StyleSheet.create({
                //selected: { color: '#000000' },
                //unselected: { color: '#000000' },
                slider: { color: '#000000' },
            }),
        },
        platform: {
            web: {
                '*': {
                    fontFamily: {
                        regular: '"MyriadPro-Regular","Lucida Sans Unicode","Lucida Grande",Arial,Helvetica,clean,sans-serif',
                        bold: '"MyriadPro-Regular","Lucida Grande", "Lucida Sans Unicode","Lucida Grande",Arial,Helvetica,clean,sans-serif',
                    },
                },
            },
        }
    }
}
