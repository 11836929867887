import React from 'react';
import { Text as NativeText, StyleSheet } from 'react-native';

export default class Text extends React.Component {
    render() {
        const {
            style,
            ...rest
        } = this.props;
        return (
            <NativeText style={style} {...rest} />
        );
    }
}

const styles = StyleSheet.create({
})
