import React, { useCallback, useEffect, useState } from "react";
import { Loading } from "react-native-web-ui-components";
import { useHistory, useParams } from "../routing";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { PLAYER_CLOSE } from "../redux/actions";
import { useDispatch } from "react-redux";

const BookIsbnRedirectScreen = () => {
  const { isbn } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [bookId, setBookId] = useState();

  const fetchBookId = useCallback(async (isbn) => {
    try {
      const response = await axios.get(process.env.API2_URL + "/books/internal/isbn/" + isbn);
      setBookId(response.data);
      // We want to land to the details page of the book, so let's forcibly close the player
      dispatch({
        type: PLAYER_CLOSE,
      });
    } catch (e) {
      if (e?.response?.status !== 404) {
        console.error(e);
      }
      history.push("/");
    }
  }, [isbn]);

  useEffect(() => {
    if (isbn) {
      // noinspection JSIgnoredPromiseFromCall
      fetchBookId(isbn);
    }
  }, [isbn, fetchBookId]);

  if (bookId === undefined) {
    return <Loading />;
  }

  return <Redirect from="*" to={"/raamatud/" + bookId.slug}/>;
};

export default BookIsbnRedirectScreen;