import React, {useEffect} from "react";
import {useUser} from "../modules/User";
import {useDispatch, useSelector} from "react-redux";
import {SET_GDPR_CONSENT} from "../redux/actions";
import mixpanel from "mixpanel-browser";

export const GdprConsent = () => {
    const {isLoggedIn} = useUser();
    const dispatch = useDispatch();
    const consent = useSelector(state => state.gdprConsent);
    const consentGiven = consent === true || isLoggedIn;

    useEffect(() => {
        if (consentGiven) {
            window.fbq("consent", "grant");
            mixpanel.opt_in_tracking();
        } else {
            window.fbq("consent", "revoke");
            mixpanel.opt_out_tracking();
        }
    }, [consentGiven]);

    // Do not show if user has made a decision or user is logged in
    if (consent !== null || isLoggedIn) {
        return null;
    }

    return (
        <div id="gdpr-consent-footer" style={styles.container}>
            <span id="message" style={styles.message}>See veebileht kasutab küpsiseid, et kuvada Teile personaliseeritud sisu.</span>
            <span id="buttons" style={styles.buttons}>
                <button style={styles.button} onClick={() => dispatch({type: SET_GDPR_CONSENT, payload: true})}>Nõustun</button>
                <button onClick={() => dispatch({type: SET_GDPR_CONSENT, payload: false})}>Ei nõustu</button>
            </span>
        </div>
    );
}

const styles = {
    container: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: 'black',
        color: 'white',
        width: '100%',
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    message: {
      marginRight: 10
    },
    buttons: {
        display: 'inline-block'
    },
    button: {
        marginRight: 10
    }
};
