import React from "react";
import {FlatList, StyleSheet, View} from "react-native";
import Text from "../components/Text";
import ScreenTitle from "../components/ScreenTitle";
import {useDispatch, useSelector} from "react-redux";
import {selectCartBooks} from "../redux/cart/cartSelectors";
import {Image} from "react-native-web-ui-components";
import Button from "../components/Button";
import {removeBooksFromCart} from "../redux/cart/cartActions";
import {useDimensions} from "react-native-responsive-ui/src";
import {selectUserSubscriptionDiscountActive} from "../redux/reducers";
import {useSubscriberDiscountCalculator} from "../hooks/useSubscriberDiscountCalculator";
import {Mixpanel} from "../utils/mixpanel";

export default () => {
  const cartContents = useSelector(selectCartBooks);

  return (
    <View style={baseStyles.container}>
      <ScreenTitle>Ostukorv</ScreenTitle>
      <View style={baseStyles.listContainer}>
        {cartContents.length === 0 ? <Text>Ostukorv on tühi</Text> : <Cart cartContents={cartContents}/>}
      </View>
    </View>
  );
}

const Cart = ({cartContents}) => {
  const {width} = useDimensions();
  const calculateDiscountPrice = useSubscriberDiscountCalculator();
  const isDiscountActive = useSelector(selectUserSubscriptionDiscountActive);
  const isWide = width > 799;

  const styles = isWide ? baseStyles : narrowStyles;

  const fullPrice = cartContents.reduce((acc, cartItem) => acc + Number.parseFloat(cartItem.price), 0);
  const discountPrice = calculateDiscountPrice(fullPrice);

  return (
    <>
      <FlatList data={cartContents} extraData={styles}
                renderItem={({item}) => <CartItem item={item} styles={styles} isUserSubscribed={isDiscountActive}/>}/>
      <View style={styles.footer}>
        <View style={styles.total}>
          <Text style={[styles.totalText, isDiscountActive && styles.strikethroughPrice]}>
            Tavakasutaja hind: {fullPrice.toFixed(2)}€
          </Text>
          <Text style={[styles.totalText, !isDiscountActive && styles.strikethroughPrice]}>
            Paketikasutaja soodushind: {discountPrice}€
          </Text>
        </View>
        <View style={styles.payButton}>
          <Button textStyle={styles.payButtonText} type={"dark"} to={"/maksmine"}>Maksma</Button>
        </View>
      </View>
    </>
  );
};

const CartItem = ({item, styles, isUserSubscribed}) => {
  const dispatch = useDispatch();
  const calculateDiscountPrice = useSubscriberDiscountCalculator();
  const label = item.author + " - " + item.title;

  const handleRemoveFromCart = () => {
    dispatch(removeBooksFromCart([item.id]));
    Mixpanel.track("Book removed from cart", {"bookId": item.id, "author": item.author, "title": item.title});
  }

  return <View style={styles.listItem}>
    <Image fixed style={styles.thumbnail} source={{uri: item.image}} alt={label}/>
    <Text style={styles.label}>{label}</Text>
    <View>
    {!isUserSubscribed ?
      <Text style={styles.price}>{item.price + "€"}</Text>
      :
      <>
        <Text
          style={[styles.price, styles.strikethroughPrice]}>{" " + item.price + "€ "}</Text>
        <Text style={styles.price}>{" " + calculateDiscountPrice(item.price) + "€ "}</Text>
      </>
    }
    </View>
    <Button type={"dark"} onPress={handleRemoveFromCart}>Eemalda</Button>
  </View>;
};

const baseStylesObject = {
  container: {
    flex: 1,
    padding: 50,
  },
  listContainer: {
    paddingTop: 20,
  },
  listItem: {
    borderBottomWidth: 1,
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    padding: 10,
  },
  thumbnail: {
    width: 90,
    height: 130,
  },
  label: {
    flex: 1,
    marginLeft: 20,
  },
  price: {
    width: 100,
    textAlign: "right",
    marginRight: 20,
  },
  footer: {
    flexDirection: "row",
    width: "100%",
    marginTop: 30,
  },
  total: {
    margin: 10,
    height: 30,
    flex: 1
  },
  totalText: {
    fontSize: 26,
  },
  strikethroughPrice: {
    textDecorationLine: "line-through",
    color: "#535353"
  },
  payButton: {
    fontSize: 26,
  },
  payButtonText: {
    fontSize: 26,
    padding: 12,
  },
};
const baseStyles = StyleSheet.create(baseStylesObject);

const narrowStyles = StyleSheet.create({
  ...baseStylesObject,
  listItem: {
    ...baseStylesObject.listItem,
    flexDirection: "column",
  },
  label: {
    ...baseStylesObject.label,
    textAlign: "center",
    marginLeft: 0,
    marginVertical: 10,
  },
  price: {
    ...baseStylesObject.price,
    textAlign: "center",
    marginBottom: 10,
    marginRight: 0,
  },
  footer: {
    ...baseStylesObject.footer,
    flexDirection: "column",
    marginTop: 2
  },
  total: {
    ...baseStylesObject.total,
    alignSelf: "center"
  },
  payButton: {
    ...baseStylesObject.payButton,
    marginTop: 30,
    alignSelf: "center"
  }

});

