import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "../routing";
import {SET_USER_DATA} from "../redux/actions";
import {EMPTY_CART} from "../redux/cart/cartActions";

export const useLogout = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    return () => {
        dispatch({
            type: EMPTY_CART,
        });
        dispatch({
            type: SET_USER_DATA,
            payload: null,
        });
        history.push("/");
    }
}

export const useUser = () => {
    let user = useSelector(state => state.user)
    if (!user) {
        user = {}
    }
    user.isLoggedIn = user && user.hasOwnProperty('apiToken')
    return user
}

export default {
    useLogout,
    useUser,
}
