import React from "react";
import {View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native-web";
import BookPreview from "./BookPreview";
import {useDispatch} from "react-redux";
import Text from "./Text";

const BooksList = props => {
    const dispatch = useDispatch()
    return (
        <View style={styles.container}>
            {props.books.map(item => renderItem({item, dispatch}))}
        </View>
    )
}

const renderItem = ({item, dispatch}) => (
  <View key={item.id} style={styles.itemContainer}>
      <BookPreview id={item.id} title={item.title} authorFirstName={item.authorFirstName} authorLastName={item.authorLastName} image={item.image} slug={item.slug}/>
      {item.subscriptionBook !== undefined && <OwnershipInfo isPurchasedBook={!item.subscriptionBook}/>}
  </View>
);

const OwnershipInfo = ({isPurchasedBook}) => (
  <View>
      <Text style={styles.ownership}>{isPurchasedBook ? "Ostetud raamat" : "Paketiraamat"}</Text>
  </View>
);

export default BooksList;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        paddingHorizontal: 50,
    },
    itemContainer: {
        marginBottom: 20,
        marginRight: 20,
    },
    ownership: {
        fontSize: 10,
        textAlign: "center"
    },
});
