export default `
  body #root {
    max-width: 100%
  }
  body a:hover {
    opacity: 1
  }

  @keyframes appear {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-2%)
    }
    100% {
      transform: translateX(0)
    }
  }

  @keyframes shrink {
    0% {
      width: 95%
    }
    100% {
      width: 90%
    }
  }
`
