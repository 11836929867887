import React, {useEffect, useState} from "react";
import {Image, StyleSheet, View} from "react-native";
import ScreenTitle from "../components/ScreenTitle";
import {Loading} from "react-native-web-ui-components";
import axios from "axios";
import Linking from "react-native-web/src/exports/Linking";
import HoverableView from "../components/HoverableView";
import {useParams} from "../routing";

export default () => {
    const [transaction, setTransaction] = useState(undefined);
    const {duration} = useParams();

    useEffect(() => {
        if (transaction === undefined && duration) {
            axios.post(process.env.API2_URL + "/checkout-gift-card", {
                duration: duration,
            }).then(({data}) => {
                setTransaction(data);
            });
        }
    }, [duration]);

    if (transaction === undefined) {
        return <Loading/>;
    }

    return (
        <View style={styles.container}>
            <ScreenTitle>Maksemeetodi valik</ScreenTitle>
            <View style={styles.listContainer}>
                {[...transaction.paymentMethods.banklinks, ...transaction.paymentMethods.cards].map(item => (
                    <HoverableView style={styles.paymentMethod} key={item.name} onPress={() => {
                        Linking.openURL(item.url);
                    }}>
                        <Image style={styles.paymentMethodImage} source={{uri: item.logo_url}}
                               alt={item.name}/>
                    </HoverableView>
                ))}
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 50,
    },
    listContainer: {
        paddingTop: 20,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap"
    },
    paymentMethod: {
        width: 196,
        height: 82,
    },
    paymentMethodImage: {
        width: 176,
        height: 62,
        margin: 10,
    },

});


